<app-header></app-header>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader subheader-2 bg-cover bg-center dark-overlay" style="background-image: url('assets/img/subheader.jpg')">
    <div class="container">
        <div class="subheader-inner">
            <h1 class="text-white">How to land your first gig as an agent</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#"> <i class="fas fa-home"></i> </a></li>
                    <li class="breadcrumb-item"><a href="#">Blog</a></li>
                    <li class="breadcrumb-item active" aria-current="page">How to land your first gig as an agent</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<!-- Blog Posts Start -->
<div class="section blog-wrapper">
    <div class="container">
        <div class="row">
            <!-- Post Start -->
            <div class="col-lg-8">
                <!-- Content Start -->
                <article class="post-single">
                    <div class="post-meta">
                        <span> <img src="assets/img/people/1.jpg" alt="author"> <a href="#">Randy Blue</a> </span>
                        <span> <i class="far fa-calendar"></i> <a href="#">March 3, 2020</a> </span>
                        <span> <i class="far fa-folder"></i> <a href="#">Real Estate</a> </span>
                        <span> <i class="fas fa-hashtag"></i> <a href="#">Rental</a>, <a href="#">Flat</a> </span>
                    </div>
                    <div class="post-thumbnail">
                        <img src="assets/img/blog/5.jpg" alt="post">
                    </div>
                    <div class="post-content">
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
                            Letraset sheets
                            containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <h4>Into the subject</h4>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. <a href="#">Lorem Ipsum has been the industry's</a> standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.
                        </p>
                        <img src="assets/img/blog/4.jpg" alt="post">
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting
                        </p>
                        <blockquote>
                            <h5>Real estate is booming</h5>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                        </blockquote>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
                            Letraset sheets
                            containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                        <div class="row">
                            <div class="col-md-6">
                                <a href="assets/img/blog/9.jpg" class="gallery-thumb">
                                    <img src="assets/img/blog/9.jpg" alt="post">
                                </a>
                            </div>
                            <div class="col-md-6">
                                <a href="assets/img/blog/7.jpg" class="gallery-thumb">
                                    <img src="assets/img/blog/7.jpg" alt="post">
                                </a>
                            </div>
                        </div>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.
                        </p>
                    </div>
                </article>
                <!-- Content End -->
                <!-- Pagination Start -->
                <div class="section pb-0 post-single-pagination-wrapper">
                    <div class="post-single-pagination post-prev">
                        <i class="fas fa-arrow-left"></i>
                        <a href="#" class="post-single-pagination-content">
                            <span>Prev Post</span>
                            <h6>How to enable better agents in an agency</h6>
                        </a>
                    </div>
                    <div class="post-single-pagination post-next">
                        <a href="#" class="post-single-pagination-content">
                            <span>Next Post</span>
                            <h6>Real estate is booming in the west and east</h6>
                        </a>
                        <i class="fas fa-arrow-right"></i>
                    </div>
                </div>
                <!-- Pagination Start -->
                <!-- Related Posts Start -->
                <div class="section section-padding">
                    <h4>Related Posts</h4>
                    <div class="row">
                        <div class="col-md-6">
                            <article class="post">
                                <div class="post-thumbnail">
                                    <a routerLink='/blog-single'><img src="assets/img/blog/1.jpg" alt="blog post"></a>
                                </div>
                                <div class="post-body">
                                    <div class="post-author">
                                        <img src="assets/img/people/2.jpg" alt="author">
                                        <div class="post-author-body">
                                            <p> <a href="#">Heather Mclayn</a> </p>
                                            <span class="post-date">March 3, 2020</span>
                                        </div>
                                    </div>
                                    <h5 class="post-title"> <a routerLink='/blog-single'>What agencies are looking for in an agent</a> </h5>
                                    <p class="post-text">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                    </p>
                                    <div class="post-controls">
                                        <a href="#" class="btn-custom secondary btn-sm">Read More</a>
                                        <div class="post-comment-authors">
                                            <span>Comments by: </span>
                                            <div class="post-comment-authors-img">
                                                <a href="#"> <img src="assets/img/people/1.jpg" alt="comment author"> </a>
                                                <a href="#"> <img src="assets/img/people/5.jpg" alt="comment author"> </a>
                                                <a href="#"> +7 </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="col-md-6">
                            <article class="post">
                                <div class="post-thumbnail">
                                    <a routerLink='/blog-single'><img src="assets/img/blog/6.jpg" alt="blog post"></a>
                                </div>
                                <div class="post-body">
                                    <div class="post-author">
                                        <img src="assets/img/people/2.jpg" alt="author">
                                        <div class="post-author-body">
                                            <p> <a href="#">Heather Mclayn</a> </p>
                                            <span class="post-date">March 3, 2020</span>
                                        </div>
                                    </div>
                                    <h5 class="post-title"> <a routerLink='/blog-single'>Flats in wisconsin are being held for rental</a> </h5>
                                    <p class="post-text">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                    </p>
                                    <div class="post-controls">
                                        <a href="#" class="btn-custom secondary btn-sm">Read More</a>
                                        <div class="post-comment-authors">
                                            <span>Comments by: </span>
                                            <div class="post-comment-authors-img">
                                                <a href="#"> <img src="assets/img/people/3.jpg" alt="comment author"> </a>
                                                <a href="#"> <img src="assets/img/people/4.jpg" alt="comment author"> </a>
                                                <a href="#"> +3 </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
                <!-- Related Posts End -->
                <!-- Comments Start -->
                <div class="comments-list section pt-0">
                    <h4>2 Comments</h4>
                    <ul>
                        <li class="comment-item">
                            <img src="assets/img/people/1.jpg" alt="comment author">
                            <div class="comment-body">
                                <h5>Randy Blue</h5>
                                <span>Posted on: January 13 2020</span>
                                <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition.</p>
                                <a href="#" class="reply-link"> Reply </a>
                            </div>
                            <ul>
                                <li class="comment-item">
                                    <img src="assets/img/people/3.jpg" alt="comment author">
                                    <div class="comment-body">
                                        <h5>Melany frank</h5>
                                        <span>Posted on: January 13 2020</span>
                                        <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches</p>
                                        <a href="#" class="reply-link"> Reply </a>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li class="comment-item">
                            <img src="assets/img/people/2.jpg" alt="comment author">
                            <div class="comment-body">
                                <h5>Heather Mclayn</h5>
                                <span>Posted on: January 13 2020</span>
                                <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches</p>
                                <a href="#" class="reply-link"> Reply </a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="comment-form section p-0">
                    <h4>Leave a Reply</h4>
                    <form method="post">
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label>Full Name</label>
                                <input type="text" class="form-control" placeholder="Full Name" name="fname" value="">
                            </div>
                            <div class="col-md-6 form-group">
                                <label>Email Address</label>
                                <input type="email" class="form-control" placeholder="Email Address" name="email" value="">
                            </div>
                            <div class="col-md-12 form-group">
                                <label>Your Message</label>
                                <textarea class="form-control" placeholder="Type your comment..." name="comment" rows="7"></textarea>
                            </div>
                            <div class="col-md-12 form-group">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="sendNotifications">
                                    <label class="custom-control-label fw-400" for="sendNotifications">Notify me when I receive a reply to my comment</label>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn-custom primary" name="button">Post comment</button>
                    </form>
                </div>
                <!-- Comments End -->
            </div>
            <!-- Post End -->
            <!-- Sidebar Start -->
            <div class="col-lg-4">
                <app-blog-sidebar></app-blog-sidebar>
            </div>
            <!-- Sidebar End -->
        </div>
    </div>
</div>
<!-- Blog Posts End -->
<app-footer></app-footer>