<app-header></app-header>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/subheader.jpg')">
    <div class="container">
        <div class="subheader-inner">
            <h1 class="text-white">Compare Listings</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#"> <i class="fas fa-home"></i> </a></li>
                    <li class="breadcrumb-item"><a href="#">Listings</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Compare Listings</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<div class="section section-padding">
    <div class="container">
        <div class="row">
            <!-- Compare Item Start -->
            <div class="col-lg-4">
                <div class="listing compare-listing-item">
                    <div class="listing-thumbnail">
                        <a routerLink='/listing-detail-v1'><img src="assets/img/listings/1.jpg" alt="listing"></a>
                        <div class="listing-badges">
                            <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                            <span class="listing-badge pending"> Pending</span>
                        </div>
                        <div class="listing-controls">
                            <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                            <a href="#" class="remove"><i class="far fa-times-circle"></i></a>
                        </div>
                    </div>
                    <div class="listing-body">
                        <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h5>
                        <span class="listing-price">3,500$ <span>/month</span> </span>
                        <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-bedroom"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Bed Rooms</h6>
                        <span>3</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-bathroom"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Bath Rooms</h6>
                        <span>3</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-ruler"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Space</h6>
                        <span>2,400 Sqft</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-picture"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Property Type</h6>
                        <span>Home</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-new"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Condition</h6>
                        <span>New</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-eye"></i>
                    <div class="acr-compare-feature-body">
                        <h6>View</h6>
                        <span>City View</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-bone"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Pet Friendly</h6>
                        <span>No</span>
                    </div>
                    <i class="custom-danger fas fa-times"></i>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-chair"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Furnished</h6>
                        <span>Yes</span>
                    </div>
                    <i class="custom-success fas fa-check"></i>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-fan"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Cooling</h6>
                        <span>No</span>
                    </div>
                    <i class="custom-danger fas fa-times"></i>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-mailbox"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Mailbox</h6>
                        <span>Yes</span>
                    </div>
                    <i class="custom-success fas fa-check"></i>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-garage"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Parking</h6>
                        <span>No</span>
                    </div>
                    <i class="custom-danger fas fa-times"></i>
                </div>
            </div>
            <!-- Compare Item End -->
            <!-- Compare Item Start -->
            <div class="col-lg-4">
                <div class="listing compare-listing-item">
                    <div class="listing-thumbnail">
                        <a routerLink='/listing-detail-v1'><img src="assets/img/listings/2.jpg" alt="listing"></a>
                        <div class="listing-badges">
                            <span class="listing-badge rent"> Rental</span>
                        </div>
                        <div class="listing-controls">
                            <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                            <a href="#" class="remove"><i class="far fa-times-circle"></i></a>
                        </div>
                    </div>
                    <div class="listing-body">
                        <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York 39531</a> </h5>
                        <span class="listing-price">2,400$ <span>/month</span> </span>
                        <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-bedroom"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Bed Rooms</h6>
                        <span>2</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-bathroom"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Bath Rooms</h6>
                        <span>1</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-ruler"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Space</h6>
                        <span>1,800 Sqft</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-picture"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Property Type</h6>
                        <span>House</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-new"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Condition</h6>
                        <span>Used</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-eye"></i>
                    <div class="acr-compare-feature-body">
                        <h6>View</h6>
                        <span>Forest View</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-bone"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Pet Friendly</h6>
                        <span>No</span>
                    </div>
                    <i class="custom-danger fas fa-times"></i>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-chair"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Furnished</h6>
                        <span>Yes</span>
                    </div>
                    <i class="custom-success fas fa-check"></i>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-fan"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Cooling</h6>
                        <span>Yes</span>
                    </div>
                    <i class="custom-success fas fa-check"></i>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-mailbox"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Mailbox</h6>
                        <span>No</span>
                    </div>
                    <i class="custom-danger fas fa-times"></i>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-garage"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Parking</h6>
                        <span>Yes</span>
                    </div>
                    <i class="custom-success fas fa-check"></i>
                </div>
            </div>
            <!-- Compare Item End -->
            <!-- Compare Item Start -->
            <div class="col-lg-4">
                <div class="listing compare-listing-item">
                    <div class="listing-thumbnail">
                        <a routerLink='/listing-detail-v1'><img src="assets/img/listings/3.jpg" alt="listing"></a>
                        <div class="listing-badges">
                            <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                            <span class="listing-badge pending"> Pending</span>
                        </div>
                        <div class="listing-controls">
                            <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                            <a href="#" class="remove"><i class="far fa-times-circle"></i></a>
                        </div>
                    </div>
                    <div class="listing-body">
                        <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Cecilia Chapman, Mankato Mississippi 96522">Cecilia Chapman, Mankato Mississippi 96522</a> </h5>
                        <span class="listing-price">2,600$ <span>/month</span> </span>
                        <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-bedroom"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Bed Rooms</h6>
                        <span>4</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-bathroom"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Bath Rooms</h6>
                        <span>3</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-ruler"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Space</h6>
                        <span>3,600 Sqft</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-picture"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Property Type</h6>
                        <span>Villa</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-new"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Condition</h6>
                        <span>New</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-eye"></i>
                    <div class="acr-compare-feature-body">
                        <h6>View</h6>
                        <span>City View</span>
                    </div>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-bone"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Pet Friendly</h6>
                        <span>Yes</span>
                    </div>
                    <i class="custom-success fas fa-check"></i>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-chair"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Furnished</h6>
                        <span>Yes</span>
                    </div>
                    <i class="custom-success fas fa-check"></i>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-fan"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Cooling</h6>
                        <span>Yes</span>
                    </div>
                    <i class="custom-success fas fa-check"></i>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-mailbox"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Mailbox</h6>
                        <span>Yes</span>
                    </div>
                    <i class="custom-success fas fa-check"></i>
                </div>
                <div class="acr-compare-feature">
                    <i class="flaticon-garage"></i>
                    <div class="acr-compare-feature-body">
                        <h6>Parking</h6>
                        <span>Yes</span>
                    </div>
                    <i class="custom-success fas fa-check"></i>
                </div>
            </div>
            <!-- Compare Item End -->
        </div>
    </div>
</div>
<app-footer></app-footer>