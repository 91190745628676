<app-header></app-header>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/subheader.jpg')">
    <div class="container">
        <div class="subheader-inner">
            <h1 class="text-white">Open House Search</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#"> <i class="fas fa-home"></i> </a></li>
                    <li class="breadcrumb-item active" aria-current="page">Open House</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<br>
<!-- Subheader End -->
<!-- Search block Started-->
<div class="col-md-5 form-group ml-auto text-right">
    <button type="submit" class="col-md-5 btn-custom secondary active"  >RESIDENTIAL</button>&nbsp;
    <button type="submit" class="col-md-5 btn-custom secondary active"  >COMMERCIAL</button>
</div>
<div class="tab-pane fade show active" id="basic-info" role="tabpanel" aria-labelledby="basic-info-tab">
    <div class="row">
        <div class="col-md-12 form-group">
            <select class="form-control" name="status">
                <option >Select By City Name Optional</option>
                <option value="	New York">	New York</option>
                <option value="	Los Angeles">	Los Angeles</option>
                <option value="Chicago">Chicago</option>
                <option value="Houston">Houston</option>
                <option >NONE</option>
            </select>
        </div>
        <div class="col-md-12 form-group">
            <input  name="content" rows="4" class="form-control" placeholder="Serch by City,Community or MLS#">
        </div>
        
        <div class="col-md-2 form-group">
            <select class="form-control" name="status">
                <option >Min Price</option>
                <option value="$20000">$20000</option>
                <option value="$30000">$30000</option>
                <option value="$40000">$40000</option>
                <option value="$50000">$50000</option>
                <option value="$60000">$60000</option>
                <option value="$70000">$70000</option>
                <option value="$80000">$80000</option>
                <option value="$90000">$90000</option>
            </select>
        </div>
        <div class="col-md-2 form-group">
            <select class="form-control" name="status">
                <option >Max Price</option>
                <option value="$20000">$20000</option>
                <option value="$30000">$30000</option>
                <option value="$40000">$40000</option>
                <option value="$50000">$50000</option>
                <option value="$60000">$60000</option>
                <option value="$70000">$70000</option>
                <option value="$80000">$80000</option>
                <option value="$90000">$90000</option>
            </select>
        </div>
        <div class="col-md-2">
            <select class="form-control" name="status">
                <option >Beds</option>
                <option value="1">1</option>
                <option value="1+">1+</option>
                <option value="2">2</option>
                <option value="2+">2+</option>
                <option value="3">3</option>
                <option value="3+">3+</option>
                <option value="4">4</option>
                <option value="4+">4+</option>
            </select>
        </div>
        <div class="col-md-2 form-group">
            <select class="form-control" name="status">
                <option >Bath Rooms</option>
                <option value="1">1</option>
                <option value="1+">1+</option>
                <option value="2">2</option>
                <option value="2+">2+</option>
                <option value="3">3</option>
                <option value="3+">3+</option>
                <option value="4">4</option>
                <option value="4+">4+</option>
            </select>
        </div>
        <div class="col-md-2">
            <select class="form-control" name="status">
                <option >Property Type</option>
                <option value="Condo">Condo</option>
                <option value="Free-HoldTown">Free-HoldTown</option>
                <option value="Condo-HoldTown">Condo-HoldTown</option>
                <option value="Detached">Detached</option>
                <option value="Semi-Detached">Semi-Detached</option>
                <option value="Duplex">Duplex</option>
            </select>
        </div>
        <div class="col-md-2 form-group">
            <select class="form-control" name="status">
                <option>For Sale</option>
                <option value="Condo">For Lease</option>
            </select><br>
        </div>
    </div>
    </div>
    <div class="row">
    <div class="col-md-5 form-group ml-auto text-right">
        <button type="submit" class="col-md-5 btn-custom secondary"  routerLink='/listing-map'>SEARCH</button>
    </div>
    </div>
<!-- Search block end-->
<div class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="row">
                    <!-- Open House Start -->
                    <div class="listing listing-list">
                        <div class="listing-thumbnail">
                            <a routerLink='/listing-detail-v1'><img src="assets/img/listings-list/8.jpg" alt="listing"></a>
                            <div class="listing-badges">
                                <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                                <span class="listing-badge pending"> Pending</span>
                            </div>
                            <div class="listing-controls">
                                <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                                <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                            </div>
                        </div>
                        <div class="listing-body">
                            <div class="listing-author">
                                <img src="assets/img/people/2.jpg" alt="author">
                                <div class="listing-author-body">
                                    <p> <a href="#">Heather Mclayn</a> </p>
                                    <span class="listing-date">March 3, 2020</span>
                                </div>
                                <div class="dropdown options-dropdown">
                                    <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <ul>
                                            <li> <a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Agent</a> </li>
                                            <li> <a href="mailto:+123456789"> <i class="fas fa-envelope"></i> Send Message</a> </li>
                                            <li> <a routerLink='/listing-detail-v1'> <i class="fas fa-bookmark"></i> Book Tour</a> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h5>
                            <span class="listing-price">3,500$ <span>/month</span> </span>
                            <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <div class="acr-listing-icons">
                                <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                    <i class="flaticon-bedroom"></i>
                                    <span class="acr-listing-icon-value">3</span>
                                </div>
                                <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                    <i class="flaticon-bathroom"></i>
                                    <span class="acr-listing-icon-value">2</span>
                                </div>
                                <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                    <i class="flaticon-ruler"></i>
                                    <span class="acr-listing-icon-value">2,499</span>
                                </div>
                            </div>
                            <div class="listing-gallery-wrapper">
                                <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                                <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                            </div>
                        </div>
                    </div>
                    <!-- Open House End -->
                    <!-- Open House list Start -->
                    <div class="listing listing-list">
                        <div class="listing-thumbnail">
                            <a routerLink='/listing-detail-v1'><img src="assets/img/listings-list/8.jpg" alt="listing"></a>
                            <div class="listing-badges">
                                <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                                <span class="listing-badge pending"> Pending</span>
                            </div>
                            <div class="listing-controls">
                                <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                                <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                            </div>
                        </div>
                        <div class="listing-body">
                            <div class="listing-author">
                                <img src="assets/img/people/2.jpg" alt="author">
                                <div class="listing-author-body">
                                    <p> <a href="#">Heather Mclayn</a> </p>
                                    <span class="listing-date">March 3, 2020</span>
                                </div>
                                <div class="dropdown options-dropdown">
                                    <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <ul>
                                            <li> <a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Agent</a> </li>
                                            <li> <a href="mailto:+123456789"> <i class="fas fa-envelope"></i> Send Message</a> </li>
                                            <li> <a routerLink='/listing-detail-v1'> <i class="fas fa-bookmark"></i> Book Tour</a> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h5>
                            <span class="listing-price">3,500$ <span>/month</span> </span>
                            <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <div class="acr-listing-icons">
                                <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                    <i class="flaticon-bedroom"></i>
                                    <span class="acr-listing-icon-value">3</span>
                                </div>
                                <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                    <i class="flaticon-bathroom"></i>
                                    <span class="acr-listing-icon-value">2</span>
                                </div>
                                <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                    <i class="flaticon-ruler"></i>
                                    <span class="acr-listing-icon-value">2,499</span>
                                </div>
                            </div>
                            <div class="listing-gallery-wrapper">
                                <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                                <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                            </div>
                        </div>
                    </div>
                    <!-- Open House list End -->
                    <!-- Open House list Start -->
                    <div class="listing listing-list">
                        <div class="listing-thumbnail">
                            <a routerLink='/listing-detail-v1'><img src="assets/img/listings-list/8.jpg" alt="listing"></a>
                            <div class="listing-badges">
                                <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                                <span class="listing-badge pending"> Pending</span>
                            </div>
                            <div class="listing-controls">
                                <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                                <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                            </div>
                        </div>
                        <div class="listing-body">
                            <div class="listing-author">
                                <img src="assets/img/people/2.jpg" alt="author">
                                <div class="listing-author-body">
                                    <p> <a href="#">Heather Mclayn</a> </p>
                                    <span class="listing-date">March 3, 2020</span>
                                </div>
                                <div class="dropdown options-dropdown">
                                    <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <ul>
                                            <li> <a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Agent</a> </li>
                                            <li> <a href="mailto:+123456789"> <i class="fas fa-envelope"></i> Send Message</a> </li>
                                            <li> <a routerLink='/listing-detail-v1'> <i class="fas fa-bookmark"></i> Book Tour</a> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h5>
                            <span class="listing-price">3,500$ <span>/month</span> </span>
                            <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <div class="acr-listing-icons">
                                <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                    <i class="flaticon-bedroom"></i>
                                    <span class="acr-listing-icon-value">3</span>
                                </div>
                                <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                    <i class="flaticon-bathroom"></i>
                                    <span class="acr-listing-icon-value">2</span>
                                </div>
                                <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                    <i class="flaticon-ruler"></i>
                                    <span class="acr-listing-icon-value">2,499</span>
                                </div>
                            </div>
                            <div class="listing-gallery-wrapper">
                                <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                                <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                            </div>
                        </div>
                    </div>
                    <!--Open House list End -->
                    <!-- Open House list Start -->
                    <div class="listing listing-list">
                        <div class="listing-thumbnail">
                            <a routerLink='/listing-detail-v1'><img src="assets/img/listings-list/8.jpg" alt="listing"></a>
                            <div class="listing-badges">
                                <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                                <span class="listing-badge pending"> Pending</span>
                            </div>
                            <div class="listing-controls">
                                <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                                <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                            </div>
                        </div>
                        <div class="listing-body">
                            <div class="listing-author">
                                <img src="assets/img/people/2.jpg" alt="author">
                                <div class="listing-author-body">
                                    <p> <a href="#">Heather Mclayn</a> </p>
                                    <span class="listing-date">March 3, 2020</span>
                                </div>
                                <div class="dropdown options-dropdown">
                                    <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <ul>
                                            <li> <a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Agent</a> </li>
                                            <li> <a href="mailto:+123456789"> <i class="fas fa-envelope"></i> Send Message</a> </li>
                                            <li> <a routerLink='/listing-detail-v1'> <i class="fas fa-bookmark"></i> Book Tour</a> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h5>
                            <span class="listing-price">3,500$ <span>/month</span> </span>
                            <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            <div class="acr-listing-icons">
                                <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                    <i class="flaticon-bedroom"></i>
                                    <span class="acr-listing-icon-value">3</span>
                                </div>
                                <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                    <i class="flaticon-bathroom"></i>
                                    <span class="acr-listing-icon-value">2</span>
                                </div>
                                <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                    <i class="flaticon-ruler"></i>
                                    <span class="acr-listing-icon-value">2,499</span>
                                </div>
                            </div>
                            <div class="listing-gallery-wrapper">
                                <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                                <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                            </div>
                        </div>
                    </div>
                    <!-- Open House list End -->
                </div>
                <!-- Pagination Start -->
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" href="#"> <i class="fas fa-chevron-left"></i> </a></li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item active">
                        <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item"><a class="page-link" href="#"> <i class="fas fa-chevron-right"></i> </a></li>
                </ul>
                <!-- Pagination End -->
            </div>
            <div class="col-lg-4">
                <div class="sidebar">
                    <div class="sidebar-widget">
                        <h5>Recent Listings</h5>
                        <!-- Listing Start -->
                        <div class="listing listing-list">
                            <div class="listing-thumbnail">
                                <a routerLink='/listing-detail-v1'><img src="assets/img/listings/1.jpg" alt="listing"></a>
                            </div>
                            <div class="listing-body">
                                <h6 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h6>
                                <span class="listing-price">3,500$ <span>/month</span> </span>
                            </div>
                        </div>
                        <!-- Listing End -->
                        <!-- Listing Start -->
                        <div class="listing listing-list">
                            <div class="listing-thumbnail">
                                <a routerLink='/listing-detail-v1'><img src="assets/img/listings/2.jpg" alt="listing"></a>
                            </div>
                            <div class="listing-body">
                                <h6 class="listing-title"> <a routerLink='/listing-detail-v1' title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York 39531</a> </h6>
                                <span class="listing-price">1,300$ <span>/month</span> </span>
                            </div>
                        </div>
                        <!-- Listing End -->
                        <!-- Listing Start -->
                        <div class="listing listing-list">
                            <div class="listing-thumbnail">
                                <a routerLink='/listing-detail-v1'><img src="assets/img/listings/3.jpg" alt="listing"></a>
                            </div>
                            <div class="listing-body">
                                <h6 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h6>
                                <span class="listing-price">2,500$ <span>/month</span> </span>
                            </div>
                        </div>
                        <!-- Listing End -->
                        <!-- Listing Start -->
                        <div class="listing listing-list">
                            <div class="listing-thumbnail">
                                <a routerLink='/listing-detail-v1'><img src="assets/img/listings/4.jpg" alt="listing"></a>
                            </div>
                            <div class="listing-body">
                                <h6 class="listing-title"> <a routerLink='/listing-detail-v1' title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York 39531</a> </h6>
                                <span class="listing-price">1,900$ <span>/month</span> </span>
                            </div>
                        </div>
                        <!-- Listing End -->
                    </div>
                    <div class="sidebar-widget sidebar-list">
                        <h5>Popular Categories</h5>
                        <ul>
                            <li> <a href="#"> Living Rooms <span>(24)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                            <li> <a href="#"> Real Estate <span>(16)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                            <li> <a href="#"> Kitchens <span>(32)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                            <li> <a href="#"> Flats <span>(22)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                            <li> <a href="#"> Luxury <span>(15)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                            <li> <a href="#"> Bed Rooms <span>(8)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>