<!-- Footer Start -->
<footer class="acr-footer footer-2">
    <!-- Footer Top Start -->
    <!-- Footer Top End -->
    <!-- Footer Middle Start -->
    <div class="footer-middle">
        <div class="container">
            <div class="row">
                <div class="col-lg-2 col-sm-4 footer-widget">
                    <h5 class="widget-title">Menu</h5>
                    <ul>
                        <li> <a routerLink='/listing-map'>Find Home</a> </li>
                        <!-- <li> <a routerLink='/submit-listing'>Add Listing</a> </li> -->
                        <li> <a routerLink='/listing-map'>Listings</a> </li>
                        <!-- <li> <a routerLink='/blog-grid'>Blog</a> </li> -->
                    </ul>
                </div>
                <div class="col-lg-2 col-sm-4 footer-widget">
                    <h5 class="widget-title">Information</h5>
                    <ul>
                        <li> <a routerLink='/about'>About Us</a> </li>
                        <li> <a routerLink='/contact'>Contact Us</a> </li>
                        <!-- <li> <a routerLink='/services'>Services</a> </li> -->
                        <!-- <li> <a routerLink='/faq'>FAQ</a> </li> -->
                    </ul>
                </div>
                <div class="col-lg-2 col-sm-4 footer-widget">
                    <h5 class="widget-title">Follow Us</h5>
                    <ul>
                        <li> <a href='https://www.facebook.com/' target="_blank">Facebook</a> </li>
                        <li> <a href='https://www.google.ca/' target="_blank">Google</a> </li>
                        <!-- <li> <a routerLink='/legal'>Cookie Policy</a> </li> -->
                        <!-- <li> <a routerLink='/legal'>Legal Terms</a> </li> -->
                    </ul>
                </div>
                <div  class="col-lg-6 footer-widget">
                    <!-- subcribtion block -->
                    <div>
                        <div class="container">
                            <div class="subheader-inner">
                                <h2 class="text-black">SUBSCRIBE TO REALTOR</h2>
                            </div>
                        </div>
                    </div>
                    <div>
                        <form [formGroup]='subd' (ngSubmit)="subcripdata()">
                        <div class="row">
                            <div class="col-md-6 form-group " >
                                <input type="text" class="form-control" placeholder="First Name" formControlName="FirstName"  required>
                                <span *ngIf="firstname.invalid && firstname.touched " class="error">*Required</span>
                            </div>
                            <div class="col-md-6 form-group">
                                <input type="text" class="form-control"  placeholder="Last Name" formControlName="LastName" required>
                                <span *ngIf="lastname.invalid && lastname.touched " class="error">*Required</span>
                            </div>
                            <div class="col-md-9 pt-2 form-group">
                                <input type="email" class="form-control"  placeholder="Type full email" formControlName="Email" required>
                                <div *ngIf="(email.invalid && email.touched) || email.dirty">
                                    <span *ngIf="email.errors?.required" class="error">* Email is Required</span>
                                    <span *ngIf="email.errors?.pattern" class="error">* Please Enter Valid Email</span>
                            
                                  </div> 
                            </div>
                            <div class="col-sm-3 pt-2 form-group">
                                <button type="submit" class="btn-custom primary"  name="submit">Subscribe</button>
                            </div>
                          <div class="row" style="margin-left: 5px;" >
                              <div class="col-md-12">
            
                                <!-- <ng-template [ngIf]="show"> <p class="alert alert-info">{{show}}</p></ng-template>
                                <ng-template [ngIf]="display"> <p class="alert alert-info">{{display}}</p></ng-template> -->
                                <!-- <div>
                                    <p class="alert alert-info">{{show}}</p>
                                </div> -->
                                <div *ngIf="show; then success else error"></div>
                                <div>
                                <ng-template #success>&nbsp;&nbsp;<h5 class="alert-info">{{show}}</h5></ng-template>
                                <ng-template #error>&nbsp;&nbsp;<h5 class="alert-info">{{show}}</h5></ng-template>
                                </div>
                             </div>   
                          </div>
                           
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer Middle End -->
    <!-- Footer Bottom Start -->
    <div class="footer-bottom">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-7">
                    <p class="m-0">&copy; Copyright 2021 - <a href="javascript:void(0);">Homelife/Miracle Reality Ltd</a> All Rights Reserved.</p>
                </div>
                <div class="col-lg-5">
                    <ul>
                        <li>Powered By <a href='https://yellow-chips.com/' target="_blank"> <img src="assets/img/yellow-chips.png" style="width:16px;"> yellow-chips.com</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer Bottom End -->
</footer>
<!-- Footer End -->