<div class="sidebar sidebar-left">
    <div class="sidebar-widget">
        <div class="acr-collapse-trigger acr-custom-chevron-wrapper">
            <h5>Recent Listing </h5>
            <div class="acr-custom-chevron">
                <span></span>
                <span></span>
            </div>
        </div>
        <div class="acr-collapsable">
            <!-- Listing Start -->
            <div class="listing listing-list">
                <div class="listing-thumbnail">
                    <a routerLink='/listing-detail-v1'><img src="assets/img/listings/1.jpg" alt="listing"></a>
                </div>
                <div class="listing-body">
                    <h6 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h6>
                    <span class="listing-price">3,500$ <span>/month</span> </span>
                </div>
            </div>
            <!-- Listing End -->
            <!-- Listing Start -->
            <div class="listing listing-list">
                <div class="listing-thumbnail">
                    <a routerLink='/listing-detail-v1'><img src="assets/img/listings/2.jpg" alt="listing"></a>
                </div>
                <div class="listing-body">
                    <h6 class="listing-title"> <a routerLink='/listing-detail-v1' title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York 39531</a> </h6>
                    <span class="listing-price">1,300$ <span>/month</span> </span>
                </div>
            </div>
            <!-- Listing End -->
            <!-- Listing Start -->
            <div class="listing listing-list">
                <div class="listing-thumbnail">
                    <a routerLink='/listing-detail-v1'><img src="assets/img/listings/3.jpg" alt="listing"></a>
                </div>
                <div class="listing-body">
                    <h6 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h6>
                    <span class="listing-price">2,500$ <span>/month</span> </span>
                </div>
            </div>
            <!-- Listing End -->
            <!-- Listing Start -->
            <div class="listing listing-list">
                <div class="listing-thumbnail">
                    <a routerLink='/listing-detail-v1'><img src="assets/img/listings/4.jpg" alt="listing"></a>
                </div>
                <div class="listing-body">
                    <h6 class="listing-title"> <a routerLink='/listing-detail-v1' title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York 39531</a> </h6>
                    <span class="listing-price">1,900$ <span>/month</span> </span>
                </div>
            </div>
            <!-- Listing End -->
        </div>
    </div>
    <!-- <div class="sidebar-widget">
        <div class="acr-collapse-trigger acr-custom-chevron-wrapper">
            <h5>Mortgage Calculator</h5>
            <div class="acr-custom-chevron">
                <span></span>
                <span></span>
            </div>
        </div>
        <div class="acr-collapsable">
            <form method="post">
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Total Amount" name="total" value="">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Down Payment" name="down-p" value="">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Interest Rate" name="interest" value="">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Loan Term" name="loan-term" value="">
                </div>
                <div class="form-group">
                    <select class="form-control" name="cycle">
                        <option value="Daily">Daily</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Yearly">Yearly</option>
                    </select>
                </div>
                <button type="submit" class="btn-custom btn-block" name="button">Calculate</button>
            </form>
        </div>
    </div> -->
</div>