<div class="acr-cs-container">
    <div class="acr-cs-content">
        <div class="acr-cs-content-head">
            <div class="acr-cs-logo">
                <a routerLink='/'><img src="assets/img/logo.png" alt="logo"></a>
            </div>
        </div>
        <div class="acr-cs-content-body">
            <div class="acr-cs-text">
                <div class="acr-dots-wrapper">
                    <div class="acr-dots"></div>
                </div>
                <h1 class="title">A Home For All Is <span class="custom-primary">Coming Soon</span> </h1>
                <p class="subtitle">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
            </div>
            <div class="acr-cs-newsletter">
                <form method="post">
                    <input type="text" placeholder="Email Address" class="form-control" name="email-newsletter" value="">
                    <button type="submit" class="btn-custom secondary" name="button">Subscribe</button>
                </form>
                <span>*We will notify you as soon as the website goes live.</span>
            </div>
        </div>
        <div class="acr-cs-content-footer">
            <p> Copyright &copy; 2020 <a href="#">AndroThemes</a> All Rights Reserved. </p>
            <ul class="social-media">
                <li> <a href="#"> <i class="fab fa-facebook-f"></i> </a> </li>
                <li> <a href="#"> <i class="fab fa-instagram"></i> </a> </li>
                <li> <a href="#"> <i class="fab fa-twitter"></i> </a> </li>
                <li> <a href="#"> <i class="fab fa-linkedin-in"></i> </a> </li>
            </ul>
        </div>
    </div>
    <div class="acr-cs-bg">
        <div class="acr-cs-bg-slider">
            <div class="acr-cs-bg-item bg-cover bg-center" style="background-image: url('assets/img/coming-soon/2.jpg')"></div>
            <div class="acr-cs-bg-item bg-cover bg-center" style="background-image: url('assets/img/coming-soon/3.jpg')"></div>
            <div class="acr-cs-bg-item bg-cover bg-center" style="background-image: url('assets/img/coming-soon/1.jpg')"></div>
        </div>
    </div>
</div>