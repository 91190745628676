<!-- Aside (Mobile Navigation) -->
<aside class="main-aside">
    <div class="aside-title">
        <div class="aside-controls aside-trigger">
            <h4>Menu</h4>
            <div class="close-btn close-dark">
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
    <div class="aside-scroll">
        <ul>
            <!-- Pages Start -->
            <li class="menu-section-title">Pages</li>
            <li class="menu-item">
                <a href="#"> <i class="flaticon-home"></i> Home</a>
            </li>
            <li class="menu-item ">
                <a routerLink='/blog-list'><i class="flaticon-writing-1"></i> Blog</a>
            </li>
            <li class="menu-item menu-item-has-children">
                <a href="#"> <i class="flaticon-layers"></i> Pages</a>
                <ul class="submenu">
                    <li class="menu-item"> <a routerLink='/about'>About Us</a> </li>
                    <li class="menu-item"> <a routerLink='/contact'>Contact Us</a> </li>
                    <li class="menu-item"> <a routerLink='/login'>Login</a> </li>
                    <li class="menu-item"> <a routerLink='/register'>Register</a> </li>
                </ul>
            </li>
            <li class="menu-item menu-item-has-children">
                <a href="#"> <i class="flaticon-list"></i> Listings</a>
                <ul class="submenu">
                    <li class="menu-item ">
                        <a routerLink='/listing-grid'>Featured Listing</a>
                    </li>
                    <li class="menu-item ">
                        <a routerLink='/listing-map'>MLS Listing</a>
                    </li>
                    <!-- <li class="menu-item ">
                        <a routerLink='/agent'>Open House</a>
                    </li> -->
                    <li class="menu-item ">
                        <a routerLink='/agency'>Mortgage Pre-Approval </a>
                    </li>
                   </ul>
            </li>
            <!-- Pages End -->
            <!-- Social Media Start -->
            <!-- <li class="menu-section-title">Get Social</li> -->
            <!-- <li class="menu-item"> <a href="https://www.facebook.com/" target="_blank"> <i class="flaticon-facebook"></i>Facebook</a> </li> -->
            <!-- <li class="menu-item"> <a href="https://www.google.ca/" target="_blank"> <i class="flaticon-google"></i> Linkedin </a> </li> -->
            <!-- <li class="menu-item"> <a href="#"> <i class="flaticon-twitter"></i> Twitter </a> </li> -->
            <!-- <li class="menu-item"> <a href="#"> <i class="flaticon-instagram"></i> Instagram </a> </li> -->
            <!-- Social Media End -->
        </ul>
    </div>
</aside>
<div class="aside-overlay aside-trigger"></div>
<!-- Header Start -->
<header class="main-header header-fw can-sticky header-1">
    <!-- Top Header Start -->
    <div class="top-header">
        <div class="top-header-inner">
            <ul class="social-media">
                <!-- <li> <a href="https://www.facebook.com/" target="_blank"> <i class="fab fa-facebook-f"></i> </a> </li>
                <li> <a href="https://www.google.ca/" target="_blank"> <i class="fab fa-google"></i> </a> </li> -->
               
            </ul>
            <ul class="top-header-nav">
                <li  *ngIf="loginstates else statechange"> <a routerLink='/login'> Login</a> </li>
                <ng-template #statechange>
                <li> <a routerLink='/login'>My Profile</a> </li>
                </ng-template>
                <li>or</li>
                <li> <a routerLink='/register'> Signup</a> </li>
            </ul>
        </div>
    </div>
    <!-- Top Header End -->
    <nav class="navbar">
        <!-- Logo -->
        <a class="navbar-brand" routerLink='/'> <img src="assets/img/logo.png" alt="logo"> </a>
        <!-- Menu -->
        <ul class="navbar-nav">
            <li class="menu-item ">
                <a href="#">Home</a>
            </li>
            <li class="menu-item ">
                <a href="#" routerLink='/about'>About Us</a>
            </li>
            <li class="menu-item ">
                <a href="#" routerLink='/listing-grid' >Featured Listing </a>
            </li>
            <li class="menu-item ">
                <a href="#" routerLink='/listing-map'>MLS Listing </a>
            </li>
            <!-- <li class="menu-item">
                <a routerLink='/agent'>Open House </a>
            </li> -->
            <li class="menu-item ">
                <a routerLink='/agency'>Mortgage Pre-Approval </a>
            </li>
            <li class="menu-item ">
                <a routerLink='/blog-list'>Blogs </a>
            </li>
             <li class="menu-item">
                <a routerLink='/contact'>Contact us  </a>
            </li>
        </ul>
        <div class="header-controls">
            <!-- Toggler -->
            <div class="aside-toggler aside-trigger">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </nav>
</header>
<!-- Header End -->