<app-header></app-header>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/subheader.jpg')">
    <div class="container">
        <div class="subheader-inner">
            <h1 class="text-white">User Details</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" aria-current="page">User Details</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<div class="section agent-wrapper">
    <div class="container">
        <div class="row">
            <!-- User Sidebar Start -->
            <div class="col-lg-4">
                <div class="sidebar sticky-sidebar sidebar-left">
                    <div class="sidebar-widget sidebar-widget-agent">
                        <!-- User Start -->
                        <div class="media sidebar-author listing-agent">
                            <img src="assets/img/agents/1.jpg" alt="agent">
                            <div class="media-body">
                                <h6> Freddy Burben </h6>
                                <span>Company Agent</span>
                            </div>
                            <div class="dropdown options-dropdown">
                                <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <ul>
                                        <li> <a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Freddy</a> </li>
                                        <li> <a href="#"> <i class="fas fa-star"></i> Save Agent</a> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- User End -->
                        <h2>About</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                    </div>
                </div>
            </div>
            <!-- User Sidebar End -->
            <!-- User Listings Start -->
            <div class="col-lg-8">
                <div class="row pt-0 section section-padding">
                    <!-- Listing Start -->
                    <div class="col-md-6">
                        <div class="listing">
                            <div class="listing-thumbnail">
                                <a routerLink='/listing-detail-v1'><img src="assets/img/listings/1.jpg" alt="listing"></a>
                                <div class="listing-badges">
                                    <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                                    <span class="listing-badge pending"> Pending</span>
                                </div>
                                <div class="listing-controls">
                                    <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                                    <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                                </div>
                            </div>
                            <div class="listing-body">
                                <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h5>
                                <span class="listing-price">3,500$ <span>/month</span> </span>
                                <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <div class="acr-listing-icons">
                                    <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                        <i class="flaticon-bedroom"></i>
                                        <span class="acr-listing-icon-value">3</span>
                                    </div>
                                    <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                        <i class="flaticon-bathroom"></i>
                                        <span class="acr-listing-icon-value">2</span>
                                    </div>
                                    <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                        <i class="flaticon-ruler"></i>
                                        <span class="acr-listing-icon-value">2,499</span>
                                    </div>
                                </div>
                                <div class="listing-gallery-wrapper">
                                    <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                                    <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Listing End -->
                    <!-- Listing Start -->
                    <div class="col-md-6">
                        <div class="listing">
                            <div class="listing-thumbnail">
                                <a routerLink='/listing-detail-v1'><img src="assets/img/listings/2.jpg" alt="listing"></a>
                                <div class="listing-badges">
                                    <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                                    <span class="listing-badge sale"> On Sale</span>
                                </div>
                                <div class="listing-controls">
                                    <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                                    <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                                </div>
                            </div>
                            <div class="listing-body">
                                <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Theodore Lowe, Azusa New York 39531">Theodore Lowe, Azusa New York 39531</a> </h5>
                                <span class="listing-price">2,300$ <span>/month</span> </span>
                                <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <div class="acr-listing-icons">
                                    <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                        <i class="flaticon-bedroom"></i>
                                        <span class="acr-listing-icon-value">2</span>
                                    </div>
                                    <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                        <i class="flaticon-bathroom"></i>
                                        <span class="acr-listing-icon-value">1</span>
                                    </div>
                                    <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                        <i class="flaticon-ruler"></i>
                                        <span class="acr-listing-icon-value">1,780</span>
                                    </div>
                                </div>
                                <div class="listing-gallery-wrapper">
                                    <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                                    <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Listing End -->
                    <!-- Listing Start -->
                    <div class="col-md-6">
                        <div class="listing">
                            <div class="listing-thumbnail">
                                <a routerLink='/listing-detail-v1'><img src="assets/img/listings/3.jpg" alt="listing"></a>
                                <div class="listing-badges">
                                    <span class="listing-badge pending"> Pending</span>
                                </div>
                                <div class="listing-controls">
                                    <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                                    <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                                </div>
                            </div>
                            <div class="listing-body">
                                <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h5>
                                <span class="listing-price">3,500$ <span>/month</span> </span>
                                <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <div class="acr-listing-icons">
                                    <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                        <i class="flaticon-bedroom"></i>
                                        <span class="acr-listing-icon-value">3</span>
                                    </div>
                                    <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                        <i class="flaticon-bathroom"></i>
                                        <span class="acr-listing-icon-value">2</span>
                                    </div>
                                    <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                        <i class="flaticon-ruler"></i>
                                        <span class="acr-listing-icon-value">2,499</span>
                                    </div>
                                </div>
                                <div class="listing-gallery-wrapper">
                                    <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                                    <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Listing End -->
                    <!-- Listing Start -->
                    <div class="col-md-6">
                        <div class="listing">
                            <div class="listing-thumbnail">
                                <a routerLink='/listing-detail-v1'><img src="assets/img/listings/4.jpg" alt="listing"></a>
                                <div class="listing-badges">
                                    <span class="listing-badge sale">On Sale</span>
                                </div>
                                <div class="listing-controls">
                                    <a href="#" class="favorite"><i class="far fa-heart"></i></a>
                                    <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                                </div>
                            </div>
                            <div class="listing-body">
                                <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="Iris Watson, Frederick Nebraska 20620">Iris Watson, Frederick Nebraska 20620</a> </h5>
                                <span class="listing-price">3,500$ <span>/month</span> </span>
                                <p class="listing-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                <div class="acr-listing-icons">
                                    <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                        <i class="flaticon-bedroom"></i>
                                        <span class="acr-listing-icon-value">3</span>
                                    </div>
                                    <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                        <i class="flaticon-bathroom"></i>
                                        <span class="acr-listing-icon-value">2</span>
                                    </div>
                                    <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                        <i class="flaticon-ruler"></i>
                                        <span class="acr-listing-icon-value">2,499</span>
                                    </div>
                                </div>
                                <div class="listing-gallery-wrapper">
                                    <a routerLink='/listing-detail-v1' class="btn-custom btn-sm secondary">View Details</a>
                                    <a href="#" data-toggle="tooltip" title="Gallery" class="listing-gallery"> <i class="fas fa-camera"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Listing End -->
                </div>
                <!-- Comments Start -->
                <div class="comment-form section p-0">
                    <h4>User Deatails</h4>
                    <form method="post">
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label>Full Name</label>
                                <input type="text" class="form-control" placeholder="Full Name" name="fname" value="">
                            </div>
                            <div class="col-md-6 form-group">
                                <label>Email Address</label>
                                <input type="email" class="form-control" placeholder="Email Address" name="email" value="">
                            </div>
                            <div class="col-md-6 form-group">
                                <label>Tel no</label>
                                <input type="text" class="form-control" placeholder="Telno" name="email" value="">
                            </div>
                            <div class="col-md-6 form-group">
                                <label>Gender</label>
                                <input type="text" class="form-control" placeholder="Telno" name="email" value="">
                            </div>
                            <div class="col-md-6 form-group">
                                <label>DOB</label>
                                <input type="text" class="form-control" placeholder="Telno" name="email" value="">
                            </div>
                            <div class="col-md-6 form-group">
                                <label>Marital Status</label>
                                <input type="text" class="form-control" placeholder="Telno" name="email" value="">
                            </div>
                            <div class="col-md-12 form-group">
                                <label>Address</label>
                                <textarea class="form-control" placeholder="Type your comment..." name="comment" rows="7"></textarea>
                            </div>
                            <div class="col-md-12 form-group">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="sendNotifications">
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn-custom primary" name="button">Update</button>&nbsp;
                        <button type="submit" class="btn-custom primary" name="button">Delete</button>
                    </form>
                </div>
                <!-- Comments End -->
            </div>
            <!-- User Listings End -->
        </div>
        <!-- Similar User Start -->
        <div class="section pb-0">
            <h4 class="section-title">Similar Users</h4>
            <div class="row">
                <!-- User Start -->
                <div class="col-lg-4">
                    <div class="acr-agent">
                        <div class="acr-dots-wrapper acr-agent-thumb">
                            <div class="acr-dots"></div>
                            <a routerLink='/agent-detail'><img src="assets/img/people/2.jpg" alt="agent"></a>
                        </div>
                        <div class="acr-agent-body">
                            <h6> <a routerLink='/agent-detail'>Rinda Flow</a> </h6>
                            <span>Expert at Company</span>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                            <a routerLink='/agent-detail' class="btn-custom secondary btn-sm">View Profile</a>
                        </div>
                    </div>
                </div>
                <!-- User End -->
                <!-- User Start -->
                <div class="col-lg-4">
                    <div class="acr-agent">
                        <div class="acr-dots-wrapper acr-agent-thumb">
                            <div class="acr-dots"></div>
                            <a routerLink='/agent-detail'><img src="assets/img/people/3.jpg" alt="agent"></a>
                        </div>
                        <div class="acr-agent-body">
                            <h6> <a routerLink='/agent-detail'>Gina Mconihon</a> </h6>
                            <span>Expert at Company</span>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                            <a routerLink='/agent-detail' class="btn-custom secondary btn-sm">View Profile</a>
                        </div>
                    </div>
                </div>
                <!-- User End -->
                <!-- User Start -->
                <div class="col-lg-4">
                    <div class="acr-agent">
                        <div class="listing-badge featured">
                            <i class="fas fa-star"></i>
                        </div>
                        <div class="acr-dots-wrapper acr-agent-thumb">
                            <div class="acr-dots"></div>
                            <a routerLink='/agent-detail'><img src="assets/img/people/4.jpg" alt="agent"></a>
                        </div>
                        <div class="acr-agent-body">
                            <h6> <a routerLink='/agent-detail'>Oliver Rasky</a> </h6>
                            <span>Expert at Company</span>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                            <a routerLink='/agent-detail' class="btn-custom secondary btn-sm">View Profile</a>
                        </div>
                    </div>
                </div>
                <!-- User End -->
            </div>
        </div>
        <!-- Similar User End -->
    </div>
</div>
<app-footer></app-footer>