<app-header></app-header>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/subheader.jpg')">
    <div class="container">
        <div class="subheader-inner">
            <h1 class="text-white">Services</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#"> <i class="fas fa-home"></i> </a></li>
                    <li class="breadcrumb-item active" aria-current="page">Services</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<!-- About Section Start -->
<div class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 mb-lg-30 acr-dots-wrapper acr-single-img-wrapper">
                <img src="assets/img/listings-list/4.jpg" alt="img">
                <div class="acr-dots"></div>
            </div>
            <div class="col-lg-6">
                <div class="section-title-wrap mr-lg-30">
                    <h5 class="custom-primary">Get Started</h5>
                    <h2 class="title">Our agents are ready to help find your home</h2>
                    <p class="subtitle">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p>
                    <ul class="acr-list">
                        <li>Lorem Ipsum is simply dummy text</li>
                        <li>Many desktop publishing packages and web page editors</li>
                        <li>There are many variations of passages of Lorem Ipsum</li>
                        <li>Internet tend to repeat predefined chunks as necessary</li>
                        <li>Contrary to popular belief, Lorem Ipsum is not simply</li>
                    </ul>
                    <a routerLink='/listing-map' class="btn-custom">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Section End -->
<!-- Icon Block Start -->
<div class="section infographics-2 light-bg bg-norepeat bg-bottom" style="background-image: url('assets/img/misc/bldg.png')">
    <div class="container">
        <div class="section-title-wrap section-header">
            <h5 class="custom-primary">Services</h5>
            <h2 class="title">You Need It, We Got It</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="acr-infographic-item">
                    <i class="flaticon-sold"></i>
                    <h5>Real Estate Services</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <a href="#"> <span>Get Started</span> <i class="fas fa-chevron-right"></i> </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="acr-infographic-item">
                    <i class="flaticon-company"></i>
                    <h5>Business Services</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <a href="#"> <span>Get Started</span> <i class="fas fa-chevron-right"></i> </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="acr-infographic-item">
                    <i class="flaticon-clipboard"></i>
                    <h5>Tax Services</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <a href="#"> <span>Get Started</span> <i class="fas fa-chevron-right"></i> </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="acr-infographic-item">
                    <i class="flaticon-checklist"></i>
                    <h5>Mortgage Calculation</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <a href="#"> <span>Get Started</span> <i class="fas fa-chevron-right"></i> </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="acr-infographic-item">
                    <i class="flaticon-sales-agent"></i>
                    <h5>Consultation Services</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <a href="#"> <span>Get Started</span> <i class="fas fa-chevron-right"></i> </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="acr-infographic-item">
                    <i class="flaticon-user"></i>
                    <h5>Services for Individuals</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <a href="#"> <span>Get Started</span> <i class="fas fa-chevron-right"></i> </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Icon Block End -->
<!-- Gallery Start -->
<div class="section section-padding">
    <div class="container">
        <div class="section-title-wrap section-header">
            <h5 class="custom-primary">Our Office</h5>
            <h2 class="title">What You're Working With</h2>
        </div>
        <div class="row gallery">
            <div class="col-lg-6">
                <a href="assets/img/services/1.jpg" class="gallery-thumb">
                    <img src="assets/img/services/1.jpg" alt="services">
                    <p class="gallery-caption">Our Team</p>
                </a>
            </div>
            <div class="col-lg-6">
                <a href="assets/img/services/2.jpg" class="gallery-thumb">
                    <img src="assets/img/services/2.jpg" alt="services">
                    <p class="gallery-caption">The Meeting Room</p>
                </a>
                <a href="assets/img/services/3.jpg" class="gallery-thumb">
                    <img src="assets/img/services/3.jpg" alt="services">
                    <p class="gallery-caption">The Office</p>
                </a>
            </div>
        </div>
    </div>
</div>
<!-- Gallery End -->
<!-- CTA Start -->
<div class="section pt-0">
    <div class="container">
        <div class="cta cta-1">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <h3>Need More Information On Real Estate?</h3>
                </div>
                <div class="offset-lg-1 col-lg-7">
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and scrambled.
                    </p>
                    <a href="#" class="btn-custom-2 light">Find Out More</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- CTA End -->
<app-footer></app-footer>