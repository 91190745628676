<app-header></app-header>
<!-- Banner Start -->
<!-- <meta name="viewport" content="width=device-width, initial-scale=1"> -->

<div class="banner banner-2 p-0">
    <div class="banner-slider" >
     <!--    <div class="banner-item">
            <div class="banner-inner bg-cover bg-center dark-overlay" style="background-image: url('../assets/img/banner/banner-1.jpg')"></div>
        </div> -->
        <div class="banner-item">
            <div id="responsive" class="banner-inner bg-cover bg-center dark-overlay" style="background-image: url('../assets/img/banner/banner-1.jpg')" ></div>
        </div>
           <div class="banner-item">
            <div id="responsive" class="banner-inner bg-cover bg-center dark-overlay" style=" background-image: url('../assets/img/banner/banner-3.jpg')" ></div>
        </div>
    </div>
    <div class="acr-arrows">
        <i class="slider-prev fas fa-arrow-left slick-arrow"></i>
        <i class="slider-next fas fa-arrow-right slick-arrow"></i>
    </div>
</div>
<!-- Banner End -->
<!-- About Section Start -->
<div class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 mb-lg-30 acr-dots-wrapper acr-single-img-wrapper">
                <img src="assets/img/person.png" alt="img">
                <div class="acr-dots"></div>
            </div>
            <div class="col-lg-6">
                <div class="section-title-wrap mr-lg-30">
                    <h5 class="custom-primary">About Us</h5>
                    <h2 class="title">We provide state of the art real estate service</h2>
                    <p class="subtitle">
                        I am a Real Estate Agent of Homelife/Miracle Reality Ltd., with in-depth experience in the GTA real estate market. I am dedicated to providing the highest level of representation that I can for my clients. With over 5 years of experience with sellers and buyers, I have a proven track record of success that is crucial to leverage in today's market! In this challenging market, I strive to give my clients top-notch customer service. If you are a homeowner preparing to sell, I will assist you in the prep stage and help you position your home to maximize your $$ in the shortest amount of time. If you are a buyer, I will share in depth knowledge of the area, recent sales comparisons, and strong negotiating skills to help you get the best deal that meets your needs! Your real estate transaction is one of the largest and most important events in your life. I am honored to assist you I look forward to building another client for life! 
                    </p>
                    <p class="subtitle">
                        When it comes to buying or selling real estate, it's important to partner with an experienced professional. Working together with, Rakshit Shelat, one of the best agent for Homelife ensures you'll get the experience, knowledge and track record you can count on; from a trusted top producing real estate agent. 
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Section End -->
<!-- search -->


<div class="container">
<app-submit-listing></app-submit-listing>
</div>
<!-- Categories Start -->
<!-- Categories End -->
<!-- Listings Slider Start -->
<!-- <div class="section section-padding pt-0 listings">
    <div class="container">
        <div class="section-title-wrap section-header flex-header">
            <div class="section-title-text">
                <h2 class="title">Featured Properties</h2>
            </div>
            <!-- <div class="acr-arrows primary-arrows">
                <i class="slider-prev fas fa-arrow-left slick-arrow"></i>
                <i class="slider-next fas fa-arrow-right slick-arrow"></i>
            </div> -->
        <!-- </div>
        <div class="listings-slider" *ngFor="let featurelistdetails of featurelistdata">
            <!-- Listing Start -->
            <!-- <div class="listing" *ngFor="let featurelistown of featurelistdetails.Results | paginate : { id : 'listing_pagination', itemsPerPage : 5,currentPage : page,totalItems:totalrecords }">
                <div class="listing-thumbnail" >
                    <a routerLink='/listing-detail-v1'><img src="{{ featurelistown.Property.Photo[0].HighResPath }}" alt="listing"></a>
                    <div class="listing-badges">
                        <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                        <span class="listing-badge pending"> Pending</span>
                    </div>
                    <div class="listing-controls">
                        <a  class="favorite" (click)="saveproperty(featurelistown.MlsNumber)"><i class="far fa-heart"></i></a>
                        <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                    </div>
                </div> -->
                <!-- <div class="listing-body" >
                    <div class="listing-author" > -->
                        <!-- <img src="" alt="owner"> -->
                        <!-- <div class="listing-author-body"> -->
                            <!-- <p> <a href="#">{{ featurelistown.Individual[0].Name }}</a> </p> -->
                            <!-- <span class="listing-date">{{ featurelistown.Property.Photo[0].LastUpdated }}</span>
                        </div>
                    </div> -->
                    <!-- <h5 class="listing-title"> <a routerLink='/listing-detail-v1' title="{{ featurelistown.Property.Address.AddressText }}">{{ featurelistown.Property.Address.AddressText }}</a> </h5>
                    <span class="listing-price">{{ featurelistown.Property.Price }} </span> -->
                    <!-- <span>/month</span> -->
                    <!-- <p class="listing-text">{{ featurelistown.PublicRemarks }}</p>
                    <div class="acr-listing-icons">
                        <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                            <i class="flaticon-bedroom"></i>
                            <span class="acr-listing-icon-value">{{ featurelistown.Building.Bedrooms }}</span>
                        </div>
                        <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                            <i class="flaticon-bathroom"></i>
                            <span class="acr-listing-icon-value">{{ featurelistown.Building.BathroomTotal }}</span>
                        </div>
                        <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                            <i class="flaticon-ruler"></i>
                            <span class="acr-listing-icon-value">{{ featurelistown.Land.SizeTotal }}</span>
                        </div>
                    </div>
                    <div class="listing-gallery-wrapper">
                        <a routerLink='/listing-detail-v1/{{ featurelistown.Id }}/{{ featurelistown.MlsNumber }}' class="btn-custom btn-sm secondary">View Details</a>
                    </div>
                </div>
            </div> --> 
            <!-- Listing End -->
        <!-- </div>
    </div>
</div> --> 


  <!-- Pagination Start -->
  <!-- <div>
    <pagination-controls class="pagination "  id="listing_pagination" maxSize="5" directionLinks="true" (pageChange)="page = $event"></pagination-controls>
</div> -->
 <!-- Pagination End -->
<!-- Listings Slider End -->
<!-- Top Listings Start -->

<!-- Top Listings End -->
<!-- Single Agent Start -->

<!-- Single Agent End -->
<!-- Agents Start -->

<!-- Agents End -->
<!-- Blog Posts Start -->
<div class="section section-padding pt-0 listings">
    <div class="container">
        <div class="section-title-wrap section-header flex-header">
            <div class="section-title-text">
                
                <h1 class="title">Featured Property</h1>
            </div>
            <div class="acr-arrows primary-arrows">
                <i class="slider-prev fas fa-arrow-left slick-arrow" (click)="prev()"></i>
                <i class="slider-next fas fa-arrow-right slick-arrow" (click)="next()"></i>
            </div>
        </div>
        <div *ngIf="loading else loaded" >
            <div class="spinner"></div>
         <!-- <div class="text-center">
            <div class="spinner-grow text-primary" id="spinner"></div>
         </div> -->

        </div>
        <ng-template #loaded>
        <div class="row">
           <ngx-slick-carousel class="carousel" 
            #slickModal="slick-carousel" 
             responsive="breakpoints" [config]="slideConfig" >
           <!-- <div *ngIf="featurelistdata else featuredatanot"> -->
           <div ngxSlickItem *ngFor="let featurelistdetails of featurelistdata" class="slide">
           <div class="listing" *ngFor="let featurelistown of featurelistdetails.Results " style="max-width: 325px;min-width:325px; height: 480px;">   
             <div class="listing-thumbnail">
               <a routerLink='/listing-detail-v1/{{ featurelistown.Id }}/{{ featurelistown.MlsNumber }}'><img src="{{ featurelistown.Property.Photo[0].HighResPath }}" alt="listing" style="max-height: 180px; min-width: 320px;"></a>
               <div class="listing-badges">
                   
                   <span class="listing-badge pending">Sale</span>
               </div>
               <div class="listing-controls">
                   <a class="favorite" (click)="saveproperty(featurelistown.MlsNumber)"><i class="far fa-heart"></i></a>
                   <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
               </div>
             </div>
             <div class="listing-body">
                               
               <h5 class="listing-title"> <a routerLink='/listing-detail-v1/{{ featurelistown.Id }}/{{ featurelistown.MlsNumber }}' title="{{ featurelistown.Property.Address.AddressText }}">{{ featurelistown.Property.Address.AddressText }}</a> </h5>
               <span class="listing-price">{{ featurelistown.Property.Price }} </span>
              
               <div class="acr-listing-icons" style="min-height: 100px;">
                   <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                       <i class="flaticon-bedroom"></i>
                       <span class="acr-listing-icon-value">{{ featurelistown.Building.Bedrooms }}</span>
                   </div>
                   <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                       <i class="flaticon-bathroom"></i>
                       <span class="acr-listing-icon-value">{{ featurelistown.Building.BathroomTotal }}</span>
                   </div>
                   <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                       <i class="flaticon-ruler"></i>
                       <span class="acr-listing-icon-value">{{ featurelistown.Land.SizeTotal }}</span>
                   </div>
                   <br>
                </div>
                
                 
                   <a routerLink='/listing-detail-v1/{{ featurelistown.Id }}/{{ featurelistown.MlsNumber }}'class="btn-custom btn-sm secondary"  id="viewdetail">View Details</a>
                  <br>
               
             </div>
            </div> 
           </div>
           <!-- </div> -->
           <!-- <ng-template #featuredatanot>
                <div class="alert alert-info" role="alert">
                    New FeatureListing Added Soon..!!
                 </div>
           </ng-template> -->
           
           </ngx-slick-carousel>
        </div>
    </ng-template>
    </div>
</div>

<div class="section section-padding light-bg">
    <div class="container">
        <div class="section-title-wrap section-header">
<!-- Blog Posts End -->
<!-- Testimonials Start -->


<div class="section section-padding">
    <div class="container">
        <div class="section-title-wrap section-header">
            <h5 class="custom-primary">Testimonials</h5>
            <h2 class="title">What Are People Saying</h2>
        </div>
        <div class="row">
            <!-- Testimonail item start -->
            <div class="col-lg-4 col-md-6">
                <div class="acr-testimonial">
                    <div class="acr-testimonial-body">
                        <h5>Perfect service</h5>
                        <div class="acr-rating-wrapper">
                            <div class="acr-rating">
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                            </div>
                        </div>
                        <p>Rakshit Shelat is a professional and talented agent, with a solid understanding of the real estate market in the GTA. Thanks to him, my home was sold at the highest price for its size in the entire community. I had moved out of the province midway through the sale, and Rakshit took care of everything so smoothly without giving me one reason to worry, especially when I was away.</p>
                    </div>
                    <div class="acr-testimonial-author">
                        <img src="assets/img/people/1.jpg" alt="testimonial">
                        <div class="acr-testimonial-author-inner">
                            <h6>Manish Patel</h6>
                            <span>Software Developer</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Testimonail item end -->
            <!-- Testimonail item start -->
            <div class="col-lg-4 col-md-6">
                <div class="acr-testimonial">
                    <div class="acr-testimonial-body">
                        <h5>Competitive prices</h5>
                        <div class="acr-rating-wrapper">
                            <div class="acr-rating">
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                            </div>
                        </div>
                        <p>We were in the process of looking for a home before our friend referred us to Raskhit and boy were we happy he did!</p>
                        <p>Raskhit was very professional and energetic which made the home buying process a very pleasant experience. He took us on multiple showings and made sure everything was safe during this time (Covid).</p>
                        <p>We signed our papers and are excited to move in.</p>
                    </div>
                    <div class="acr-testimonial-author">
                        <img src="assets/img/people/2.jpg" alt="testimonial">
                        <div class="acr-testimonial-author-inner">
                            <h6>Melani B.</h6>
                            <span>Insurace Agent</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Testimonail item end -->
            <!-- Testimonail item start -->
            <div class="col-lg-4 col-md-12">
                <div class="acr-testimonial">
                    <div class="acr-testimonial-body">
                        <h5>Great agents</h5>
                        <div class="acr-rating-wrapper">
                            <div class="acr-rating">
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                                <i class="fas fa-star active"></i>
                            </div>
                        </div>
                        <p>Rakshit Shelat is so professional! His paperwork is quick and accurate. He is very knowledgeable and always has the answers to my real estate questions. When showing my rented property, Rakshit was very mindful and courteous of my tenant's schedule and needs.</p>
                        <p>Rakshit’s attention to detail and thorough attitude has made the purchase and sale process very easy and much less stressful than I anticipated.</p>

                    </div>
                    <div class="acr-testimonial-author">
                        <img src="assets/img/people/3.jpg" alt="testimonial">
                        <div class="acr-testimonial-author-inner">
                            <h6>Paul Ghelot</h6>
                            <span>Businessman</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Testimonail item end -->
        </div>
    </div>
</div>
<!-- Testimonials End -->
<app-footer></app-footer>
