<app-header></app-header>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/subheader.jpg')">
    <div class="container">
        <div class="subheader-inner">
            <h1 class="text-white">Custom Our Appointment</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#"> <i class="fas fa-home"></i> </a></li>
                    <li class="breadcrumb-item"><a href="#">Custom Our Appointment</a></li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
        <div class="row">
            <div class="col-lg-12">
                    <!-- Form Start -->
                    <br>
                    <div class="comment-form">
                        <form method="">
                            <div class="row pl-2" >
                                <div class="col-md-6 form-group">
                                    <label>Full Name</label>
                                    <input type="text" class="form-control" placeholder="Full Name" name="fname" value="">
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Last Name</label>
                                    <input type="text" class="form-control" placeholder="Last Name" name="subject" value="">
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Phone Number</label>
                                    <input type="text" class="form-control" placeholder="Phone Number" name="phone" value="">
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Email Address</label>
                                    <input type="email" class="form-control" placeholder="Email Address" name="email" value="">
                                </div>
                                <div class="col-md-12 form-group">
                                    <label>Your Message</label>
                                    <textarea class="form-control" placeholder="Type your message..." name="comment" rows="7"></textarea>
                                </div>
                            </div>
                            <button type="submit" class="btn-custom primary col-sm-3" name="button">Update Appointment</button>
                        </form>
                    </div>
                    <!-- form End -->
            </div>
        </div>
<app-footer></app-footer>