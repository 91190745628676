<app-header></app-header>
<!-- Subheader Start -->
<div class="header-1"></div>
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/aboutus.jpg')">
    <div class="container">
        <div class="subheader-inner">
            <h1 class="text-white">About Us</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#"> <i class="fas fa-home"></i> </a></li>
                    <li class="breadcrumb-item active" aria-current="page">About Us</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<!-- About Section Start -->
<div class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 mb-lg-30 acr-dots-wrapper acr-single-img-wrapper">
                <img src="assets/img/person.png" alt="img">
                <div class="acr-dots"></div>
            </div>
            <div class="col-lg-6">
                <div class="section-title-wrap mr-lg-30">
                    <h5 class="custom-primary">About Us</h5>
                    <h2 class="title">We provide state of the art real estate service</h2>
                    <p class="subtitle">
                       I am a Real Estate Agent of Homelife/Miracle Reality Ltd., with in-depth experience in the GTA real estate market. I am dedicated to providing the highest level of representation that I can for my clients. With over 5 years of experience with sellers and buyers, I have a proven track record of success that is crucial to leverage in today's market! In this challenging market, I strive to give my clients top-notch customer service. If you are a homeowner preparing to sell, I will assist you in the prep stage and help you position your home to maximize your $$ in the shortest amount of time. If you are a buyer, I will share in depth knowledge of the area, recent sales comparisons, and strong negotiating skills to help you get the best deal that meets your needs! Your real estate transaction is one of the largest and most important events in your life. I am honored to assist you I look forward to building another client for life! 
                    </p>
                    <p class="subtitle">
                        When it comes to buying or selling real estate, it's important to partner with an experienced professional. Working together with, Rakshit Shelat, one of the best agent for Homelife ensures you'll get the experience, knowledge and track record you can count on; from a trusted top producing real estate agent. 
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Section End -->
<hr>
<app-footer></app-footer>