<app-header></app-header>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/subheader.jpg')">
    <div class="container">
        <div class="subheader-inner">
            <h1 class="text-white">FAQ</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#"> <i class="fas fa-home"></i> </a></li>
                    <li class="breadcrumb-item active" aria-current="page">FAQ</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<div class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="general-tab" data-toggle="tab" href="#general" role="tab" aria-controls="general" aria-selected="true">General</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="listings-tab" data-toggle="tab" href="#listings" role="tab" aria-controls="listings" aria-selected="false">Listings</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
                        <!-- FAQs Start -->
                        <div class="accordion with-gap" id="faqAccordions1">
                            <div class="card">
                                <div class="card-header" data-toggle="collapse" role="button" data-target="#faqOne" aria-expanded="true" aria-controls="faqOne">
                                    How can I start with buying a home?
                                </div>
                                <div id="faqOne" class="collapse show" data-parent="#faqAccordions1">
                                    <div class="card-body">
                                        <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                                            tempor,
                                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                                            Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                        </p>
                                        <ul class="acr-list mb-0">
                                            <li> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                            <li> Many desktop publishing packages and web page editors now use Lorem Ipsum </li>
                                            <li> There are many variations of passages of Lorem Ipsum </li>
                                            <li> Internet tend to repeat predefined chunks as necessary </li>
                                            <li> Contrary to popular belief, Lorem Ipsum is not simply random text. </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" data-toggle="collapse" role="button" data-target="#faqTwo" aria-expanded="false" aria-controls="faqTwo">
                                    Is the service free?
                                </div>
                                <div id="faqTwo" class="collapse" data-parent="#faqAccordions1">
                                    <div class="card-body">
                                        <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                                            tempor,
                                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                                            Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                        </p>
                                        <ul class="acr-list mb-0">
                                            <li> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                            <li> Many desktop publishing packages and web page editors now use Lorem Ipsum </li>
                                            <li> There are many variations of passages of Lorem Ipsum </li>
                                            <li> Internet tend to repeat predefined chunks as necessary </li>
                                            <li> Contrary to popular belief, Lorem Ipsum is not simply random text. </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" data-toggle="collapse" role="button" data-target="#faqThree" aria-expanded="false" aria-controls="faqThree">
                                    How many agents can I have at once?
                                </div>
                                <div id="faqThree" class="collapse" data-parent="#faqAccordions1">
                                    <div class="card-body">
                                        <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                                            tempor,
                                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                                            Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                        </p>
                                        <ul class="acr-list mb-0">
                                            <li> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                            <li> Many desktop publishing packages and web page editors now use Lorem Ipsum </li>
                                            <li> There are many variations of passages of Lorem Ipsum </li>
                                            <li> Internet tend to repeat predefined chunks as necessary </li>
                                            <li> Contrary to popular belief, Lorem Ipsum is not simply random text. </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- FAQs End -->
                    </div>
                    <div class="tab-pane fade" id="listings" role="tabpanel" aria-labelledby="listings-tab">
                        <!-- FAQs Start -->
                        <div class="accordion with-gap" id="faqAccordions2">
                            <div class="card">
                                <div class="card-header" data-toggle="collapse" role="button" data-target="#faqFour" aria-expanded="true" aria-controls="faqFour">
                                    How can I submit my listing?
                                </div>
                                <div id="faqFour" class="collapse show" data-parent="#faqAccordions2">
                                    <div class="card-body">
                                        <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                                            tempor,
                                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                                            Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                        </p>
                                        <ul class="acr-list mb-0">
                                            <li> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                            <li> Many desktop publishing packages and web page editors now use Lorem Ipsum </li>
                                            <li> There are many variations of passages of Lorem Ipsum </li>
                                            <li> Internet tend to repeat predefined chunks as necessary </li>
                                            <li> Contrary to popular belief, Lorem Ipsum is not simply random text. </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" data-toggle="collapse" role="button" data-target="#faqFive" aria-expanded="false" aria-controls="faqFive">
                                    For how long does my listing stay posted?
                                </div>
                                <div id="faqFive" class="collapse" data-parent="#faqAccordions2">
                                    <div class="card-body">
                                        <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                                            tempor,
                                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                                            Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                        </p>
                                        <ul class="acr-list mb-0">
                                            <li> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                            <li> Many desktop publishing packages and web page editors now use Lorem Ipsum </li>
                                            <li> There are many variations of passages of Lorem Ipsum </li>
                                            <li> Internet tend to repeat predefined chunks as necessary </li>
                                            <li> Contrary to popular belief, Lorem Ipsum is not simply random text. </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" data-toggle="collapse" role="button" data-target="#faqSix" aria-expanded="false" aria-controls="faqSix">
                                    Can I submit listings for free?
                                </div>
                                <div id="faqSix" class="collapse" data-parent="#faqAccordions2">
                                    <div class="card-body">
                                        <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                                            tempor,
                                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                                            Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                        </p>
                                        <ul class="acr-list mb-0">
                                            <li> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                            <li> Many desktop publishing packages and web page editors now use Lorem Ipsum </li>
                                            <li> There are many variations of passages of Lorem Ipsum </li>
                                            <li> Internet tend to repeat predefined chunks as necessary </li>
                                            <li> Contrary to popular belief, Lorem Ipsum is not simply random text. </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- FAQs End -->
                    </div>
                </div>
                <!-- Contact Form Start -->
                <div class="section pb-0">
                    <div class="section-title-wrap section-header">
                        <h5 class="custom-primary">Contact Us</h5>
                        <h2 class="title">Still Got Questions?</h2>
                        <p class="subtitle">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p>
                    </div>
                    <div class="comment-form">
                        <form method="post">
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label>Full Name</label>
                                    <input type="text" class="form-control" placeholder="Full Name" name="fname" value="">
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Email Address</label>
                                    <input type="email" class="form-control" placeholder="Email Address" name="email" value="">
                                </div>
                                <div class="col-md-12 form-group">
                                    <label>Your Message</label>
                                    <textarea class="form-control" placeholder="Type your message..." name="comment" rows="7"></textarea>
                                </div>
                            </div>
                            <button type="submit" class="btn-custom primary" name="button">Send Message</button>
                        </form>
                    </div>
                </div>
                <!-- Contact Form End -->
            </div>
            <div class="col-lg-4">
                <div class="sidebar">
                    <div class="sidebar-widget">
                        <form method="post">
                            <h5>Search Posts</h5>
                            <div class="search-wrapper">
                                <input type="text" class="form-control" placeholder="Search" name="sidebar-search" value="">
                                <button type="submit" class="btn-custom" name="button"><i class="flaticon-search"></i></button>
                            </div>
                        </form>
                    </div>
                    <div class="sidebar-widget">
                        <h5>Recent Posts</h5>
                        <article class="media">
                            <a href="#"><img src="assets/img/blog-sm/1.jpg" alt="post"></a>
                            <div class="media-body">
                                <h6> <a href="#">Green neighborhood starts tomorrow</a> </h6>
                                <span>January 22, 2020</span>
                            </div>
                        </article>
                        <article class="media">
                            <a href="#"><img src="assets/img/blog-sm/2.jpg" alt="post"></a>
                            <div class="media-body">
                                <h6> <a href="#">How to enable better agents in an agency</a> </h6>
                                <span>January 24, 2020</span>
                            </div>
                        </article>
                        <article class="media">
                            <a href="#"><img src="assets/img/blog-sm/3.jpg" alt="post"></a>
                            <div class="media-body">
                                <h6> <a href="#">Real estate is booming in the west and east</a> </h6>
                                <span>January 26, 2020</span>
                            </div>
                        </article>
                        <article class="media">
                            <a href="#"><img src="assets/img/blog-sm/4.jpg" alt="post"></a>
                            <div class="media-body">
                                <h6> <a href="#">What agencies are looking for in an agent</a> </h6>
                                <span>January 28, 2020</span>
                            </div>
                        </article>
                    </div>
                    <div class="sidebar-widget sidebar-list">
                        <h5>Popular Categories</h5>
                        <ul>
                            <li> <a href="#"> Living Rooms <span>(24)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                            <li> <a href="#"> Real Estate <span>(16)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                            <li> <a href="#"> Kitchens <span>(32)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                            <li> <a href="#"> Flats <span>(22)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                            <li> <a href="#"> Luxury <span>(15)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                            <li> <a href="#"> Bed Rooms <span>(8)</span> <i class="fas fa-chevron-right"></i> </a> </li>
                        </ul>
                    </div>
                    <div class="sidebar-widget tags">
                        <h5>Popular Tags</h5>
                        <a href="#">House</a>
                        <a href="#">Real Estate</a>
                        <a href="#">Building</a>
                        <a href="#">Agency</a>
                        <a href="#">Family</a>
                        <a href="#">Acres</a>
                        <a href="#">Baths</a>
                        <a href="#">Rooms</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>