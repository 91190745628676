<app-header></app-header>
<div class="acr-auth-container">
    <div class="acr-auth-content">
        <form [formGroup]="forgotdata" (ngSubmit)="forgotpwd()">
            <div class="auth-text">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                <h3>Forget PassWord</h3>
            </div>
            <div class="form-group">
                <label>Username</label>
                <input type="text"  class="form-control form-control-light" placeholder="Username" formControlName="username" >
                <span class="error" *ngIf="username.invalid && username.touched">*Required</span>
            </div>
            <!-- <div class="form-group">
                <label>Descrption</label>
                <input type="text"  class="form-control form-control-light" placeholder="Description"  formControlName="description">
                <span class="error" *ngIf="description.invalid && description.touched">*Required</span>
            </div>
            <div class="form-group">
                <label>Password</label>
                <input type="password"  class="form-control form-control-light" placeholder="Password" formControlName="forgotpass">
                <span class="error" *ngIf="forgotpass.invalid && forgotpass.touched ">*Required</span>
            </div> -->
            <!-- <div class="form-group">
                <a href="#" class="forgot-password" >Forgot Password?</a>
            </div> -->
            <a> <button type="submit" class="btn-custom secondary btn-block">Submit</button></a>
            <div class="auth-seperator">
                <span>OR</span>
            </div>
            <div class="social-login">
                <button type="button" class="acr-social-login facebook"><i class="fab fa-facebook-f"></i> Continue with Facebook </button>
                <button type="button" class="acr-social-login google"><i class="fab fa-google"></i> Continue with Google</button>
            </div>
            <p class="text-center mb-0">Don't have an account? <a routerLink='/register'>Create One</a> </p>
        </form>
    </div>
    <div class="acr-auth-bg">
        <div class="acr-auth-bg-slider acr-cs-bg-slider">
            <div class="acr-auth-bg-item bg-cover bg-center" style="background-image: url('assets/img/coming-soon/2.jpg')">
                <div class="acr-auth-quote">
                    <h6>Quote of the day</h6>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
            </div>
            <div class="acr-auth-bg-item bg-cover bg-center" style="background-image: url('assets/img/coming-soon/3.jpg')">
                <div class="acr-auth-quote">
                    <h6>Quote of the day</h6>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
            </div>
            <div class="acr-auth-bg-item bg-cover bg-center" style="background-image: url('assets/img/coming-soon/1.jpg')">
                <div class="acr-auth-quote">
                    <h6>Quote of the day</h6>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
            </div>
        </div>
    </div>
</div>