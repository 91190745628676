<app-header></app-header>
<div class="row">
    <div class="col-md-12">
    <div class="acr-auth-content">
        <form [formGroup]='loginuser' (ngSubmit)="loggeduser()">
            <div class="auth-text">
               
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
                 <br>
                 <br>
                <h1>Log In</h1>
            </div>
            <div class="form-group">
                <label>Username</label>
                <input type="text"  class="form-control form-control-light" placeholder="Username" formControlName="UserName" >
                <span class="error" *ngIf="username.invalid && username.touched">*Required</span>
            </div>
            <div class="form-group">
                <label>Password</label>
                <input type="password"  class="form-control form-control-light" placeholder="Password" formControlName="Password">
                <span class="error" *ngIf="pwd.invalid && pwd.touched ">*Required</span>
            </div>
            <div class="form-group">
                <a routerLink="/forgetpassoword" class="forgot-password" >Forgot Password?</a>
            </div>
            <a> <button type="submit" class="btn-custom secondary btn-block">Login</button></a>
           
            
            <p class="text-center mb-0">Don't have an account? <a routerLink='/register'>Create One</a> </p>
        </form>
    </div>
    <!-- <div class="acr-auth-bg">
        <div class="acr-auth-bg-slider acr-cs-bg-slider">
            <div class="acr-auth-bg-item bg-cover bg-center" style="background-image: url('assets/img/coming-soon/2.jpg')">
                <div class="acr-auth-quote">
                    <h6>Quote of the day</h6>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
            </div>
            <div class="acr-auth-bg-item bg-cover bg-center" style="background-image: url('assets/img/coming-soon/3.jpg')">
                <div class="acr-auth-quote">
                    <h6>Quote of the day</h6>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
            </div>
            <div class="acr-auth-bg-item bg-cover bg-center" style="background-image: url('assets/img/coming-soon/1.jpg')">
                <div class="acr-auth-quote">
                    <h6>Quote of the day</h6>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                </div>
            </div>
        </div>
    </div> -->
    </div> 
</div>