<app-header></app-header>
<div class="header-1"></div>
<!-- Subheader Start -->
<div class="subheader subheader-2 user-subheader bg-cover bg-center" style="background-image: url('assets/img/subheader-2.jpg')">
    <div class="container">
        <div class="media">
            <img src="assets/img/people/1.jpg" alt="agent">
            <div class="media-body">
                <h3 class="text-white">Randy Blue</h3>
                <span class="user-email">randy_blue@hotmail.com</span>
            </div>
            <a routerLink='/submit-listing' class="btn-custom secondary mr-0">Submit Listing <i class="fas mr-0 fa-plus"></i></a>
        </div>
    </div>
</div>
<!-- Subheader End -->
<div class="section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4">
                <div class="sidebar sticky-sidebar user-nav sidebar-left">
                    <ul>
                        <li> <a routerLink='/profile'> Edit Profile</a> </li>
                        <li> <a routerLink='/profile-listing'>My Listings</a> </li>
                        <li> <a class="active" routerLink='/profile-saved-listing'>Saved Listings</a> </li>
                        <li> <a class="logout" href="#"><i class="flaticon-shut-down-button"></i> Logout</a> </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="acr-empty-section">
                    <i class="flaticon-home"></i>
                    <h3>You Haven't Saved Any Listings</h3>
                    <p>You still havent saved any listings yet, Go back to the listings page and check some of your favorite listings</p>
                    <a routerLink='/listing-map' class="btn-custom">Go to Listings</a>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>