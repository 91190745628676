<!-- Aside (Mobile Navigation) -->
<aside class="main-aside">
    <div class="aside-title">
        <div class="aside-controls aside-trigger">
            <h4>Menu</h4>
            <div class="close-btn close-dark">
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
    <div class="aside-scroll">
        <ul>
            <!-- Pages Start -->
            <li class="menu-section-title">Pages</li>
            <li class="menu-item menu-item-has-children">
                <a href="#"> <i class="flaticon-home"></i> Home Pages</a>
                <ul class="submenu">
                    <li class="menu-item"> <a routerLink='/'>Home v1</a> </li>
                    <li class="menu-item"> <a routerLink='/home-v2'>Home v2</a> </li>
                    <li class="menu-item"> <a routerLink='/home-v3'>Home v3</a> </li>
                    <li class="menu-item"> <a routerLink='/home-v4'>Home v4</a> </li>
                    <li class="menu-item"> <a routerLink='/home-v5'>Home v5</a> </li>
                </ul>
            </li>
            <li class="menu-item menu-item-has-children">
                <a href="#"><i class="flaticon-writing-1"></i> Blog</a>
                <ul class="submenu">
                    <li class="menu-item menu-item-has-children">
                        <a routerLink='/blog-grid'>Blog Archive</a>
                        <ul class="submenu">
                            <li class="menu-item"> <a routerLink='/blog-grid'>Grid View</a> </li>
                            <li class="menu-item"> <a routerLink='/blog-list'>List View</a> </li>
                        </ul>
                    </li>
                    <li class="menu-item">
                        <a routerLink='/blog-single'>Blog Single</a>
                    </li>
                </ul>
            </li>
            <li class="menu-item menu-item-has-children">
                <a href="#"> <i class="flaticon-layers"></i> Pages</a>
                <ul class="submenu">
                    <li class="menu-item"> <a routerLink='/about'>About Us</a> </li>
                    <li class="menu-item"> <a routerLink='/services'>Services</a> </li>
                    <li class="menu-item"> <a routerLink='/faq'>FAQ</a> </li>
                    <li class="menu-item"> <a routerLink='/pricing'>Pricing</a> </li>
                    <li class="menu-item"> <a routerLink='/contact'>Contact Us</a> </li>
                    <li class="menu-item"> <a routerLink='/coming-soon'>Coming Soon</a> </li>
                    <li class="menu-item"> <a routerLink='/login'>Login</a> </li>
                    <li class="menu-item"> <a routerLink='/register'>Register</a> </li>
                    <li class="menu-item"> <a routerLink='/legal'>Legal</a> </li>
                </ul>
            </li>
            <li class="menu-item menu-item-has-children">
                <a href="#"> <i class="flaticon-list"></i> Listings</a>
                <ul class="submenu">
                    <li class="menu-item menu-item-has-children">
                        <a routerLink='/listing-map'>Listings Archive</a>
                        <ul class="submenu">
                            <li class="menu-item"> <a routerLink='/listing-grid'>Grid View</a> </li>
                            <li class="menu-item"> <a routerLink='/listing-list'>List View</a> </li>
                            <li class="menu-item"> <a routerLink='/listing-map'>Map View</a> </li>
                        </ul>
                    </li>
                    <li class="menu-item menu-item-has-children">
                        <a routerLink='/listing-detail-v1'>Listing Details</a>
                        <ul class="submenu">
                            <li class="menu-item"> <a routerLink='/listing-detail-v1'>Listing Details v1</a> </li>
                            <li class="menu-item"> <a routerLink='/listing-detail-v2'>Listing Details v2</a> </li>
                            <li class="menu-item"> <a routerLink='/listing-detail-v3'>Listing Details v3</a> </li>
                        </ul>
                    </li>
                    <li class="menu-item"> <a routerLink='/submit-listing'>Submit Listing</a> </li>
                    <li class="menu-item"> <a routerLink='/compare-listing'>Compare Listings</a> </li>
                </ul>
            </li>
            <li class="menu-item menu-item-has-children">
                <a routerLink='/agent'> <i class="flaticon-headset"></i> Agents</a>
                <ul class="submenu">
                    <li class="menu-item"> <a routerLink='/agent'>Agents Archive</a> </li>
                    <li class="menu-item"> <a routerLink='/agent-detail'>Agent Details</a> </li>
                    <li class="menu-item menu-item-has-children">
                        <a routerLink='/profile'>Agent Profile</a>
                        <ul class="submenu">
                            <li class="menu-item"> <a routerLink='/profile'>My Account</a> </li>
                            <li class="menu-item"> <a routerLink='/profile-listing'>My Listings</a> </li>
                            <li class="menu-item"> <a routerLink='/profile-saved-listing'>Saved Listings</a> </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li class="menu-item menu-item-has-children">
                <a routerLink='/agency'> <i class="flaticon-company"></i> Agency</a>
                <ul class="submenu">
                    <li class="menu-item"> <a routerLink='/agency'>Agency Archive</a> </li>
                    <li class="menu-item"> <a routerLink='/agency-detail'>Agency Details</a> </li>
                </ul>
            </li>
            <!-- Pages End -->
            <!-- Social Media Start -->
            <li class="menu-section-title">Get Social</li>
            <li class="menu-item"> <a href="#"> <i class="flaticon-facebook"></i>Facebook</a> </li>
            <li class="menu-item"> <a href="#"> <i class="flaticon-linkedin"></i> Linkedin </a> </li>
            <li class="menu-item"> <a href="#"> <i class="flaticon-twitter"></i> Twitter </a> </li>
            <li class="menu-item"> <a href="#"> <i class="flaticon-instagram"></i> Instagram </a> </li>
            <!-- Social Media End -->
        </ul>
    </div>
</aside>
<div class="aside-overlay aside-trigger"></div>
<!-- Header Start -->
<header class="main-header can-sticky header-fw header-1">
    <nav class="navbar">
        <!-- Logo -->
        <a class="navbar-brand" routerLink='/'> <img src="assets/img/logo.png" alt="logo"> </a>
        <!-- Menu -->
        <ul class="navbar-nav">
            <li class="menu-item menu-item-has-children">
                <a href="#">Home Pages</a>
                <ul class="submenu">
                    <li class="menu-item"> <a routerLink='/'>Home v1</a> </li>
                    <li class="menu-item"> <a routerLink='/home-v2'>Home v2</a> </li>
                    <li class="menu-item"> <a routerLink='/home-v3'>Home v3</a> </li>
                    <li class="menu-item"> <a routerLink='/home-v4'>Home v4</a> </li>
                    <li class="menu-item"> <a routerLink='/home-v5'>Home v5</a> </li>
                </ul>
            </li>
            <li class="menu-item menu-item-has-children">
                <a href="#">Blog</a>
                <ul class="submenu">
                    <li class="menu-item menu-item-has-children">
                        <a routerLink='/blog-grid'>Blog Archive</a>
                        <ul class="submenu">
                            <li class="menu-item"> <a routerLink='/blog-grid'>Grid View</a> </li>
                            <li class="menu-item"> <a routerLink='/blog-list'>List View</a> </li>
                        </ul>
                    </li>
                    <li class="menu-item">
                        <a routerLink='/blog-single'>Blog Single</a>
                    </li>
                </ul>
            </li>
            <li class="menu-item menu-item-has-children mega-menu-wrapper">
                <a href="#">Pages</a>
                <ul class="submenu">
                    <li>
                        <img src="assets/img/megamenu.png" alt="img">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="mega-menu-item">
                                        <h5>Featured Listings</h5>
                                        <p>
                                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC,
                                            making it over 2000 years old.
                                        </p>
                                        <a routerLink='/listing-map' class="btn-custom secondary">View All</a>
                                    </div>
                                </div>
                                <div class="offset-lg-1 col-lg-3">
                                    <div class="mega-menu-item">
                                        <h5>Pages</h5>
                                        <a routerLink='/about'>About Us</a>
                                        <a routerLink='/services'>Services</a>
                                        <a routerLink='/faq'>FAQ</a>
                                        <a routerLink='/pricing'>Pricing</a>
                                        <a routerLink='/contact'>Contact Us</a>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="mega-menu-item">
                                        <h5>Other Pages</h5>
                                        <a routerLink='/coming-soon'>Coming Soon</a>
                                        <a routerLink='/login'>Login</a>
                                        <a routerLink='/register'>Register</a>
                                        <a routerLink='/legal'>Legal</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </li>
            <li class="menu-item menu-item-has-children">
                <a href="#">Listings</a>
                <ul class="submenu">
                    <li class="menu-item menu-item-has-children">
                        <a routerLink='/listing-map'>Listings Archive</a>
                        <ul class="submenu">
                            <li class="menu-item"> <a routerLink='/listing-grid'>Grid View</a> </li>
                            <li class="menu-item"> <a routerLink='/listing-list'>List View</a> </li>
                            <li class="menu-item"> <a routerLink='/listing-map'>Map View</a> </li>
                        </ul>
                    </li>
                    <li class="menu-item menu-item-has-children">
                        <a routerLink='/listing-detail-v1'>Listing Details</a>
                        <ul class="submenu">
                            <li class="menu-item"> <a routerLink='/listing-detail-v1'>Listing Details v1</a> </li>
                            <li class="menu-item"> <a routerLink='/listing-detail-v2'>Listing Details v2</a> </li>
                            <li class="menu-item"> <a routerLink='/listing-detail-v3'>Listing Details v3</a> </li>
                        </ul>
                    </li>
                    <li class="menu-item"> <a routerLink='/submit-listing'>Submit Listing</a> </li>
                    <li class="menu-item"> <a routerLink='/compare-listing'>Compare Listings</a> </li>
                </ul>
            </li>
            <li class="menu-item menu-item-has-children">
                <a routerLink='/agent'>Agents</a>
                <ul class="submenu">
                    <li class="menu-item"> <a routerLink='/agent'>Agents Archive</a> </li>
                    <li class="menu-item"> <a routerLink='/agent-detail'>Agent Details</a> </li>
                    <li class="menu-item menu-item-has-children">
                        <a routerLink='/profile'>Agent Profile</a>
                        <ul class="submenu submenu-right">
                            <li class="menu-item"> <a routerLink='/profile'>My Account</a> </li>
                            <li class="menu-item"> <a routerLink='/profile-listing'>My Listings</a> </li>
                            <li class="menu-item"> <a routerLink='/profile-saved-listing'>Saved Listings</a> </li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li class="menu-item menu-item-has-children">
                <a routerLink='/agency'>Agency</a>
                <ul class="submenu">
                    <li class="menu-item"> <a routerLink='/agency'>Agency Archive</a> </li>
                    <li class="menu-item"> <a routerLink='/agency-detail'>Agency Details</a> </li>
                </ul>
            </li>
        </ul>
        <div class="header-controls">
            <ul class="header-controls-inner d-none d-lg-flex">
                <li>
                    <a routerLink='/submit-listing' class="btn-custom primary">Submit Listing <i class="fas fa-plus"></i> </a>
                </li>
            </ul>
            <!-- Toggler -->
            <div class="aside-toggler aside-trigger">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </nav>
</header>
<!-- Header End -->