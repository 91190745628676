<app-header></app-header>
<!-- Subheader Start -->
<div class="header-1"></div>
<div class="subheader bg-cover bg-center dark-overlay" style="background-image: url('assets/img/mlsListing.jpg')">
    <div class="container">
        <div class="subheader-inner">
            <h1 class="text-white">MLS Listing</h1>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#"> <i class="fas fa-home"></i> </a></li>
                    <li class="breadcrumb-item active" style="border:0" aria-current="page">MLS Listing</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<!-- Subheader End -->
<div class="section section-padding">
    <div class="container">
        <!-- mapping serach block -->
            <!-- Listings Start -->
                    <!-- Filter Start -->
            <div class="row"><div class="col-md-12">
                    <form [formGroup]="searchdata">
                        <div class="col-md-12 form-group ml-auto text-right">
                            <button type="submit"  class="btn1 active"(click)="resi()">RESIDENTIAL</button>&nbsp;
                            <button type="submit"  class="btn1" (click)="com()">COMMERCIAL</button>
                      </div>
                      <div class="col-md-12">
                          <div class="row">
                              <div class="col-md-12 form-group">
                                <select class="form-control" name="status" formControlName="cityname">
                                    <option value="">Select By City Name Optional</option>
                                    <option value="{{ cityname.name }}" *ngFor="let cityname of dropdowndata ">{{ cityname.name }}</option>
                                </select>
                              </div>
                              <div class="col-md-12 form-group">
                                <input  name="content" rows="4" class="form-control" placeholder="Search by City,Community or MLS#" formControlName="Mlsmo">
                              </div>
                            <!-- <div class="col-md-2 form-group">
                                <select class="form-control" name="status">
                                    <option value=""disabled selected hidden>Min Price</option>
                                    <option value="20000">20000</option>
                                    <option value="30000">30000</option>
                                    <option value="40000">40000</option>
                                    <option value="50000">50000</option>
                                    <option value="60000">60000</option>
                                    <option value="70000">70000</option>
                                    <option value="80000">80000</option>
                                    <option value="90000">90000</option>
                                </select>
                            </div>
                            <div class="col-md-2 form-group">
                                <select class="form-control" name="status">
                                    <option value=""disabled selected hidden >Max Price</option>
                                    <option value="20000">20000</option>
                                    <option value="30000">30000</option>
                                    <option value="40000">40000</option>
                                    <option value="50000">50000</option>
                                    <option value="60000">60000</option>
                                    <option value="70000">70000</option>
                                    <option value="80000">80000</option>
                                    <option value="90000">90000</option>
                                </select>
                            </div>
                            <div class="col-md-2 form-group">
                                <select class="form-control" name="status">
                                    <option value=""disabled selected hidden>Bedrooms</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </select>
                            </div>
                            <div class="col-md-2 form-group">
                                <select class="form-control" name="status" >
                                    <option value="" disabled selected hidden>Bath Rooms</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </select>
                            </div> -->
                              <!-- <div class="col-md-2 form-group">
                                  <select class="form-control" name="status" formControlName="propertytype" >
                                      <option >Property Type</option> -->
                                      <!-- 0-No Preference|1-House|2-Duplex|3-Triplex|5-Residential Commercial Mix|
                                      6-Mobile Home|12-Special Purpose|14-Other|16-Row / Townhouse|17-Apartment
                                      |19-Fourplex
                                      |20-Garden Home
                                      |26-Modular|27-Manufactured Home/Mobile|28-Commercial Apartment|29-Manufactured Home -->
                                      <!-- <option value="0">No Preference</option>
                                      <option value="1">House</option>
                                      <option value="2">Duplex</option>
                                      <option value="3">Triplex</option>
                                      <option value="5">Residential Commercial Mix</option>
                                      <option value="6">Mobile Home</option>
                                      <option value="12">Special Purpose</option>
                                      <option value="14">Other</option>
                                      <option value="16">Row / Townhouse</option>
                                      <option value="17">Apartment</option>
                                      <option value="19">Fourplex</option>
                                      <option value="20">Garden Home</option>
                                      <option value="26">Modular</option>
                                      <option value="27">Manufactured Home/Mobile</option>
                                      <option value="28">Commercial Apartment</option>
                                      <option value="29">Manufactured Home</option>
                                  </select>
                              </div> -->
                              <!-- <div class="col-md-2 form-group">
                              <select class="form-control" name="status" >
                                <option value=""disabled selected hidden>transectiontype</option>
                                      <option value="2">For Sale</option>
                                      <option value="3">For Reant</option>
                                  </select><br>
                              </div> -->
                          </div>
                          </div>
                          <div class="row">
                          <div class="col-md-5 form-group ml-auto text-right">
                              <button type="submit" class="col-md-5 btn-custom secondary" (click)=" searchfirst()">SEARCH</button>
                          </div>
                          </div>
                          </form>
                    <!-- Filter End -->

                
                
                    <!-- Search Mlsno Start -->
                    <div *ngIf="search">
                    <h2 class="title">Search Details</h2>
                    <br>
                    <!-- Listing Start -->
                    <div class="center">
                    <div class="row" style="float:center;">
                    <div class="col-lg-12" *ngIf="searchbyMlsno">
                        <div *ngIf="loading else loaded" >
                            <div class="spinner"></div>
                        </div>
                      <ng-template #loaded>
                        <div class="row"><div class="col-md-12">
                        <div *ngIf="mlsresult.Results.length >0 else nodata">
                       
                        <div class="listing" *ngFor="let searchdata of mlsresult.Results  "  style="max-width: 450px; height: 620px;margin:10px;">
                            
                            <div class="listing-thumbnail">
                                <a routerLink='/listing-detail-v1/{{ searchdata.Id }}/{{ searchdata.MlsNumber }}'><img src="{{ searchdata.Property.Photo[0].HighResPath }}" alt="listing"></a>
                                <!-- <div class="listing-badges">
                                    <span class="listing-badge featured"> <i class="fas fa-star"></i> </span>
                                    <span class="listing-badge sale"> On Sale</span>
                                </div> -->
                                <div class="listing-controls">
                                    <a  class="favorite" (click)="saveproperty(searchdata.MlsNumber)"><i class="far fa-heart"></i></a>                    <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                                    <!-- <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a> -->
                                    <!-- <a href="#" class="fly-to-location" data-lat="{{ searchdata.Property.Address.Longitude }}" data-lng="{{ searchdata.Property.Address.Latitude }}"><i class="fas fa-map-marker-alt"></i></a> -->
                                </div>
                            </div>
                            <div class="listing-body" >
                                <div class="listing-author">
                                    <div class="listing-author-body">
                                    <!-- <span class="listing-date">{{ searchdata.Property.Photo[0].LastUpdated }}</span> -->
                                    </div>
                                    <!-- <div class="dropdown options-dropdown">
                                        <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></a>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <ul>
                                                <li> <a href="tel:+123456789"> <i class="fas fa-phone"></i> Call Agent</a> </li>
                                                <li> <a href="mailto:+123456789"> <i class="fas fa-envelope"></i> Send Message</a> </li>
                                                <li> <a routerLink='/listing-detail-v1'> <i class="fas fa-bookmark"></i> Book Tour</a> </li> -->
                                            <!-- </ul>
                                        </div>
                                    </div> --> 
                                </div>
                                <h5 class="listing-title"> <a routerLink='/listing-detail-v1/{{ searchdata.Id }}/{{ searchdata.MlsNumber }}' title="{{ searchdata.Property.Address.AddressText }}">{{ searchdata.Property.Address.AddressText }}</a></h5>
                                <span class="listing-price">{{ searchdata.Property.Price }} </span>
                                <!-- <p class="listing-text">{{ searchdata.PublicRemarks }}</p> -->
                                <div class="acr-listing-icons" style="min-height: 100px;">
                                    <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                        <i class="flaticon-bedroom"></i>
                                        <span class="acr-listing-icon-value">{{ searchdata.Building.BathroomTotal }}</span>
                                    </div>
                                    <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                        <i class="flaticon-bathroom"></i>
                                        <span class="acr-listing-icon-value">{{ searchdata.Building.Bedrooms }}</span>
                                    </div>
                                    <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                        <i class="flaticon-ruler"></i>
                                        <span class="acr-listing-icon-value">{{ searchdata.Land.SizeTotal }}</span>
                                    </div>
                                </div>
                                <div class="listing-gallery-wrapper">
                                    <a routerLink='/listing-detail-v1/{{ searchdata.Id }}/{{ searchdata.MlsNumber }}' class="btn-custom btn-sm secondary">View Details</a>
                                </div>
                            </div>
                        </div>
                        </div>
                        </div> 
                        </div>
                        <ng-template #nodata>
                            <div class="row"><div class="col-md-12">
                                <div class="alert alert-info">
                                   <p style="min-width:400px;text-align: center; overflow: auto;">Sorry NO Record's To Show ..!!!</p>
                                
                                
                               </div>
                            </div></div>
                          </ng-template> 
                        </ng-template>
                    </div></div></div>
                    <!-- Listing End -->
                      <!-- Pagination Start -->
                        <!-- <div>
                            <pagination-controls class="pagination "  id="listing_pagination" maxSize="5" directionLinks="true" (pageChange)="page = $event"></pagination-controls>
                        </div> -->
                      <!-- Pagination End -->
                    </div>
                    <!-- Search Mlsno End -->
                    <!-- Dropdown Search Start-->
                    <div class="section section-padding pt-0 listings" *ngIf="dropstates">
                        <div class="container">
                            <div class="section-title-wrap section-header flex-header">
                                <div class="section-title-text">
                                    <h2 class="title">Search Details</h2>
                                </div>
                                <!-- <div class="acr-arrows primary-arrows">
                                    <i class="slider-prev fas fa-arrow-left slick-arrow"  (click)="prev()"></i>
                                    <i class="slider-next fas fa-arrow-right slick-arrow" (click)="next()"></i>
                                </div> -->
                            </div>
                            <div class="listings-slider" >
                                <div *ngIf="loading" >
                                    <div class="spinner"></div>
                                       
                                </div> 
                              <div *ngIf=" dropdownsearchdetails.Results.length > 0 else nodatadrop">  
                               <div>
                                <!-- Listing Start -->
                                
                                    
                                    <div class="row">
                                        <div class="col-md-12">
                                           
                                        <div class="row">
                                        
                                        <div  class="listing" *ngFor="let featurelistdetails of dropdownsearchdetails.Results | paginate : { id : 'listing_pagination', itemsPerPage : 3,currentPage : page,totalItems:totalrecords } " style="max-width: 340px; height: 520px;margin: 10px;" >
                                          
                                        
                                            <div class="listing-thumbnail" >
                                         <a routerLink='/listing-detail-v1/{{ featurelistdetails.Id }}/{{ featurelistdetails.MlsNumber }}'><img src="{{ featurelistdetails.Property.Photo[0].HighResPath }}" alt="listing" style="max-height: 180px; min-width: 320px; margin: 10px;"></a>
                                        <div class="listing-badges">
                                            <!-- <span class="listing-badge featured"> <i class="fas fa-star"></i> </span> -->
                                            <!-- <span class="listing-badge pending"> Pending</span> -->
                                        </div>
                                        <div class="listing-controls">
                                            <a  class="favorite" (click)="saveproperty(featurelistdetails.MlsNumber)"><i class="far fa-heart"></i></a>
                                            <a href="#" class="compare"><i class="fas fa-sync-alt"></i></a>
                                        </div>
                                         </div>
                                         <div class="listing-body" >
                                        <div class="listing-author" >
                                            <!-- <img src="" alt="owner"> -->
                                            <div class="listing-author-body">
                                                <!-- <p> <a href="#">{{ featurelistdetails.Individual[0].Name }}</a> </p> -->
                                                <!-- <span class="listing-date">{{ featurelistdetails.Property.Photo[0].LastUpdated }}</span> -->
                                            </div>
                                        </div>
                                        <h5 class="listing-title"> <a routerLink='/listing-detail-v1/{{ featurelistdetails.Id }}/{{ featurelistdetails.MlsNumber }}' title="{{ featurelistdetails.Property.Address.AddressText }}">{{ featurelistdetails.Property.Address.AddressText }}</a> </h5>
                                        <span class="listing-price">{{ featurelistdetails.Property.Price }} </span>
                                        <!-- <span>/month</span> -->
                                        <!-- <p class="listing-text">{{ featurelistdetails.PublicRemarks }}</p> -->
                                        <div class="acr-listing-icons" style="min-height:80px;">
                                            <div class="acr-listing-icon" data-toggle="tooltip" title="Beds">
                                                <i class="flaticon-bedroom"></i>
                                                <span class="acr-listing-icon-value">{{ featurelistdetails.Building.Bedrooms }}</span>
                                            </div>
                                            <div class="acr-listing-icon" data-toggle="tooltip" title="Bathrooms">
                                                <i class="flaticon-bathroom"></i>
                                                <span class="acr-listing-icon-value">{{ featurelistdetails.Building.BathroomTotal }}</span>
                                            </div>
                                            <div class="acr-listing-icon" data-toggle="tooltip" title="Square Feet">
                                                <i class="flaticon-ruler"></i>
                                                <span class="acr-listing-icon-value">{{ featurelistdetails.Land.SizeTotal }}</span>
                                            </div>
                                        </div>
                                        <div class="listing-gallery-wrapper">
                                            <a routerLink='/listing-detail-v1/{{ featurelistdetails.Id }}/{{ featurelistdetails.MlsNumber }}' class="btn-custom btn-sm secondary" id="viewdetail">View Details</a>
                                        </div>
                                        </div>
                                        
                                         </div>
                                         
                                         
                                        </div>
                                        </div>  
                                    </div>
                                    
                                <!-- Listing End -->
                              <!-- </div> -->
                               </div>
                              </div> 
                        </div>
                        <ng-template #nodatadrop>
                            <div class="alert alert-info" role="alert">
                                <p style="min-width:700px;text-align: center;float:center; overflow: auto;">Sorry NO Record's To Show ..!!!</p>
                              </div>
                         </ng-template>  
                          <!-- Pagination Start -->
                            <div>
                                <pagination-controls class="pagination "  id="listing_pagination" maxSize="5" directionLinks="true" (pageChange)="page = $event"></pagination-controls>
                            </div>
                          <!-- Pagination End -->
                    </div>
                    <!-- Dropdown Search End-->
                
                  
            </div></div>
 </div>
 </div>
 <app-footer></app-footer>